import React, { useContext, useState } from "react";
import { Typography, Row, Col, Modal } from "antd";
import "../../assets/css/Font.css";
import { BusinessContext } from "../../context/BusinessContext";
import aboutimg from "../../assets/images/video-cover-image-4.jpg";

const { Title } = Typography;

const ResGallery = () => {

  const { businessInfo } = useContext(BusinessContext);
  const galleries = businessInfo?.businessInfo?.[0]?.gallery_info || [];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  // Show modal with selected image
  const showModal = (image) => {
    setCurrentImage(image);
    setIsModalVisible(true);
  };

  // Close modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentImage(null);
  };

  return (
    <>
      {/* Banner Section */}
      <div className="menuBanner">
        <img
          src={aboutimg}
          alt="Menu Banner"
          style={{ width: "100%", height: "235px", objectFit: "cover" }}
        />
        <div className="innerBannerTitle">
          <Title level={2} style={{ color: "#fff",margin:'0' }}>
            Our Gallery
          </Title>
        </div>
      </div>

      {/* Gallery Section */}
      <div className="custom_container">
        <div className="innerCommonWrap">
        <Title level={3} className="mt0" style={{ textAlign: "center", marginBottom: "30px" }}>
          Check Out Our - "Image Gallery"
        </Title>
        <Row gutter={[16, 16]} >
          {galleries.map((image, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <div className="galleryWrap" onClick={() => showModal(image)} >
                <img src={image.caption_img}  alt={image.caption_name_en} title={image.caption_name_en}  />
              </div>
            </Col>
          ))}
        </Row>

        {/* Modal to show large image */}
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          centered
          width={800}>
          {currentImage && (
            <><div>{currentImage.caption_name_en}</div>
            <img
              src={currentImage.caption_img}
              alt={currentImage.caption_name_en}
              style={{ width: "100%", borderRadius: "8px" }}
            />
            </>
          )}
        </Modal>
      </div>
      </div>
    </>
  );
};

export default ResGallery;
