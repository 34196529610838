import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography } from "antd";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import "./Openinghour.css";
import axios from "axios";
import { BusinessContext } from "../../context/BusinessContext";
const { Title } = Typography;

const Openinghours = () => {
  const { businessInfo, loading } = useContext(BusinessContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  const locationInfo =
    businessInfo?.businessInfo?.[0]?.location_info?.[0] || {};
  const AddressInfo = [
    locationInfo.house_number,
    locationInfo.street_name_en,
    locationInfo.city_en,
    locationInfo.postcode,
    locationInfo.country_en,
  ]
    .filter(Boolean) // Ensures only truthy values are included (avoids undefined or null)
    .join(" "); // Joins the address components with a space

  const contactInfo = {
    phone: businessInfo?.businessInfo?.[0]?.restaurant_phone || "N/A",
    email: businessInfo?.businessInfo?.[0]?.email_address || "N/A",
    address: AddressInfo,
    mapSrc: `https://maps.google.it/maps?&q=${encodeURIComponent(
      AddressInfo
    )}&output=embed`, // Encodes the address for safe URL usage
  };
  // Function to transform the API data
  const transformStoreHours = (storeHours) => {
    // List of all days in a week (Monday to Sunday)
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // Create a map of days from the API response
    const storeHoursMap = storeHours.reduce((acc, item) => {
      acc[item.days] = item;
      return acc;
    }, {});

    // Return a transformed array with all days of the week
    return daysOfWeek.map((day) => {
      const {
        available_from_time_1,
        available_to_time_1,
        available_from_time_2,
        available_to_time_2,
      } = storeHoursMap[day] || {};

      let time;
      if (!available_from_time_1 && !available_from_time_2) {
        time = "Closed";
      } else {
        const slot1 =
          available_from_time_1 && available_to_time_1
            ? `${available_from_time_1} - ${available_to_time_1}`
            : null;
        const slot2 =
          available_from_time_2 && available_to_time_2
            ? `${available_from_time_2} - ${available_to_time_2}`
            : null;

        time = [slot1, slot2].filter(Boolean).join(" | "); // Join non-null slots
      }

      return { day, time };
    });
  };

  const storeHours = businessInfo?.businessInfo?.[0]?.storehours_info || [];
  const openingHours = transformStoreHours(storeHours);
  //console.log("time", businessInfo?.businessInfo?.[0]?.storehours_info);
  const currentDay = new Date().toLocaleString("en-US", { weekday: "long" });

  return (
    <div className="custom_container">
      <Row gutter={[16, 16]}>
        {/* Opening Hours */}
        <Col xs={24} md={12}>
          <div className="openingHours">
            <Title level={3} className="sectionHeading">
              {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                ?.opening_hours_en || `Opening Hours`}
            </Title>
            <ul className="hoursList">
              {openingHours.map((item, index) => (
                <li
                  key={index}
                  className={`hourItem ${
                    item.day === currentDay ? "highlighted" : "dimmed"
                  } ${item.time === "Closed" ? "closed" : ""}`}
                >
                  <span className="day">{item.day}</span>
                  <span className="time">{item.time}</span>
                </li>
              ))}
            </ul>
           
          </div>

        {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.blinking_text_en && (
        <div className="special-message">
          <p>{businessInfo.businessInfo[0].homelng[0].blinking_text_en}</p>
          </div>
      )}
        </Col>

        {/* Map and Contact Info */}
        <Col xs={24} md={12}>
          <div className="hometimeDetails">
            <Title level={3} className="sectionHeading">
              {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.our_location_en ||
                `Contact Us`}
            </Title>
            <ul className="hometimeList">
              <li className="hometimeItem">
                <PhoneOutlined
                  style={{
                    color: "#1890ff",
                    fontSize: "20px",
                    marginRight: "8px",
                  }}
                />
                <span>
                  <b>
                    {" "}
                    {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                      ?.phone_no_en || "Phone"}
                    :
                  </b>{" "}
                  <address>
                    {" "}
                    <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
                  </address>
                </span>
              </li>

              <li className="hometimeItem">
                <EnvironmentOutlined
                  style={{
                    color: "#1890ff",
                    fontSize: "20px",
                    marginRight: "8px",
                  }}
                />
                <span>
                  <b>
                    {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                      ?.address_en || "Address"}
                    :
                  </b>{" "}
                  <address>{contactInfo.address}</address>
                </span>
              </li>
              <li className="hometimeItem">
                <MailOutlined
                  style={{
                    color: "#1890ff",
                    fontSize: "20px",
                    marginRight: "8px",
                  }}
                />
                <span>
                  <b>
                    {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.email_en ||
                      "Email"}
                    :
                  </b>{" "}
                  <address>{contactInfo.email}</address>
                </span>
              </li>
              <li className="map">
                <iframe
                  title="Google Map"
                  width="100%"
                  height="200px"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src={contactInfo.mapSrc}
                  allowFullScreen=""
                ></iframe>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Openinghours;
