import React from "react";
import { Typography, Row, Col, Card, Tag } from "antd";
import "../../assets/css/Font.css";
import "../../assets/css/Offers.css"; // Custom styles for spacing and layout
import aboutimg from "../../assets/images/video-cover-image-4.jpg";
const { Title, Paragraph } = Typography;

const Offers = () => {
  const offers = [
    {
      title: "10% Off Your First Order",
      description: "Save 10% on your first purchase and make it memorable!",
      image: "",
      available_days: "Monday to Friday",
      expiry_date: "Expires: 31st Jan 2025",
      min_order: "£20",
      availability: "Delivery Only",
      categories: "Special Chicken Khaiber, Masala Supreme",
    },
    {
      title: "Free Delivery for Orders Above £50",
      description: "Free delivery on all orders over £50. Shop more, save more!",
      image: "",
      available_days: "Everyday",
      expiry_date: "Expires: 15th Feb 2025",
      min_order: "£50",
      availability: "Delivery and Collection",
      categories: "All Items",
    },
    {
      title: "Buy 1 Get 1 Free",
      description: "Buy one and get another absolutely free! Limited offer.",
      image: "",
      available_days: "Weekends Only",
      expiry_date: "Expires: 28th Feb 2025",
      min_order: "No Minimum",
      availability: "Collection Only",
      categories: "Sarson Batta Murgh",
    },
  ];

  const coupons = [
    {
      code: "WELCOME15",
      description: "15% off on your first order. Use now!",
      expiry: "Expires: 31st Jan 2025",
      availability: "Delivery",
      categories: "All Items",
    },
    {
      code: "SUPERDEAL",
      description: "Flat £5 off on orders above £20.",
      expiry: "Expires: 15th Feb 2025",
      availability: "Delivery and Collection",
      categories: "All Items",
    },
    {
      code: "FREESHIPPING",
      description: "Free shipping on all orders over £30.",
      expiry: "Expires: 28th Feb 2025",
      availability: "Collection",
      categories: "All Items",
    },
  ];

  return (
    <>
      {/* Banner Section */}
      <div className="menuBanner">
        <img
          src={aboutimg}
          alt="Menu Banner"
          style={{ width: "100%", height: "235px", objectFit: "cover" }}
        />
        <div className="innerBannerTitle">
          <Title level={2} style={{ color: "#fff", margin: "0" }}>
            Offers & Coupons
          </Title>
        </div>
      </div>

      {/* Offers Section */}
      <div className="custom_container contactSection">
        <Title
          level={3}
          className="offercustomHeading"
          style={{
            textAlign: "left",
            marginBottom: "15px", // Increased margin between heading and content
          }}
        >
          Current Offers
        </Title>
        <Row gutter={[16, 24]}>
          {offers.map((offer, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
             <Card
  hoverable
  style={{ borderRadius: "10px", overflow: "hidden" }}
  cover={offer.image ? (
    <img
      alt={offer.title}
      src={offer.image}
      style={{
        height: "180px",
        objectFit: "cover",
        borderBottom: "1px solid #f0f0f0",
        marginBottom: "15px", // Added spacing between image and content
      }}
    />
  ) : null} // Only render the image if it exists
>
  <Title level={5} style={{ marginTop: "0px" }}>{offer.title}</Title>
  <Paragraph type="secondary">{offer.description}</Paragraph>
  <div className="offerTags">
    {offer.available_days && (
      <Tag color="blue">Days: {offer.available_days}</Tag>
    )}
    {offer.expiry_date && (
      <Tag color="red">{offer.expiry_date}</Tag>
    )}
    {offer.min_order && (
      <Tag color="green">Min Order: {offer.min_order}</Tag>
    )}
    {offer.availability && (
      <Tag color="purple">{offer.availability}</Tag>
    )}
    {offer.categories && (
      <Tag color="gold">For: {offer.categories}</Tag>
    )}
  </div>
</Card>

            </Col>
          ))}
        </Row>
     
        <Title
          level={3}
          className="offercustomHeading"
          style={{
            textAlign: "left",
            marginBottom: "15px", // Increased margin between heading and content
          }}
        >
          Coupons Available
        </Title>
        <Row gutter={[16, 24]}>
          {coupons.map((coupon, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              <Card
                hoverable
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  textAlign: "center",
                }}
              >
                <Tag
                  color="green"
                  style={{
                    fontSize: "16px",
                    padding: "5px 10px",
                    fontWeight: "bold",
                  }}
                >
                  {coupon.code}
                </Tag>
                <Paragraph type="secondary" style={{ margin: "10px 0" }}>
                  {coupon.description}
                </Paragraph>
                <div className="offerTags">
                  {coupon.availability && (
                    <Tag color="purple">{coupon.availability}</Tag>
                  )}
                  {coupon.categories && (
                    <Tag color="gold">For: {coupon.categories}</Tag>
                  )}
                </div>
                <Paragraph style={{ fontSize: "14px", color: "#ff4d4f" }}>
                  {coupon.expiry}
                </Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Offers;
