import React, { useState, useEffect } from "react";
import { Typography, Row, Col } from "antd";
import "../../assets/css/Font.css";
import parse from "html-react-parser";
import Loader from "../Loader/";
import { fetchAllergyNutritional } from "../../utils/api";

const { Title, Paragraph } = Typography;

const AllergyNutritional = () => {
  const [cmsData, setCMSData] = useState(null); // Single object to hold CMS data
  const [loading, setLoading] = useState(true);

  // Fetch CMS data
  const getCmsInfo = async () => {
    try {
      const fetchedData = await fetchAllergyNutritional();
      if (fetchedData) {
        setCMSData(fetchedData.data); // Assuming AllergyNutritional returns an array
      } else {
        console.error("Failed to fetch CMS data");
      }
    } catch (error) {
      console.error("Error fetching CMS data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCmsInfo();
  }, []);

  // Show loading state if data is not yet fetched
  if (loading) {
    return (
      <Loader />
    );
  }


  // Check if cmsData exists
  if (!cmsData) {
    return (
      <div className="custom_container">
        <Title level={4} className="textCenter">
          No data available
        </Title>
      </div>
    );
  }

  return (
    <>
      {/* Banner Section */}
      <div className="menuBanner">
        <img
          src={cmsData.banner_img || "https://via.placeholder.com/1920x235"} // Fallback image if no banner is provided
          alt={cmsData.page_name_en || "Banner"}
          style={{ width: "100%", height: "235px", objectFit: "cover" }}
        />
        <div className="innerBannerTitle">
          <Title level={2} style={{ color: "#fff", margin: "0" }}>
            {cmsData.page_name_en || "Nutritional & Allergy Guide"}
          </Title>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="custom_container">
        <Row gutter={32} className="innerCommonWrap">
          <Col span={24}>
            <Title level={4} className="mt0">
              {cmsData.page_name_en || "Nutritional & Allergy Guide"}
            </Title>
            {cmsData.page_content_en ? (
              <Paragraph className="innerPtext">
                {parse(cmsData.page_content_en)}
              </Paragraph>
            ) : (
              <Paragraph className="innerPtext">
                Content coming soon...
              </Paragraph>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AllergyNutritional;
