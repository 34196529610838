import React, { createContext, useReducer, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const CartContext = createContext();

const initialState = {
  Cartitems: [],
  cookiesId: null,
};

const cartapi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-API-KEY": process.env.REACT_APP_API_KEY,
  },
});

const cartReducer = (state, action) => {
  switch (action.type) {
    case "SET_CART":
      // Ensure Cartitems is an array
      const Cartitems = action.payload.Cartitems ? action.payload.Cartitems : [];
      return {
        ...state,
        Cartitems,
      };
    case "SET_COOKIES_ID":
      return { ...state, cookiesId: action.payload };
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Initialize `cookie_id`
  useEffect(() => {
    console.log("Checking cookies_id before reading cookies...");
    let cookiesId = Cookies.get("cookie_id");
    console.log("Initial cookies_id from cookie:", cookiesId);
  
    if (!cookiesId) {
      cookiesId = `cookie_${Date.now()}`;
     // console.log("Generated new cookies_id:", cookiesId);
  
      Cookies.set("cookie_id", cookiesId, {
        expires: 7,
        secure: true,
        sameSite: "Strict",
      });
  
     // console.log("Set cookies_id in cookie:", cookiesId);
    }
  
    //console.log("Dispatching cookies_id:", cookiesId);
    dispatch({ type: "SET_COOKIES_ID", payload: cookiesId });
  }, []);
  

  const fetchCart = async () => {
    if (!state.cookiesId) return;
    try {
      const response = await cartapi.get("/cartlist", { params: { cookie_id: state.cookiesId } });
      // Ensure response.data is an array
      const Cartitems = response.data ? response.data : [];
      dispatch({ type: "SET_CART", payload: { Cartitems } });
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Failed to fetch cart Cartitems:", error);
      setError("There was an error fetching your cart. Please try again later.");
      setLoading(false); // Set loading to false in case of error
    }
  };
  // Fetch cart items
  useEffect(() => {
    fetchCart();
  }, [state.cookiesId]);

  // Add item to cart
  const addItem = async (item, orderType, UberEatsComparisonRate) => {
    try {
      const data = {
        cookie_id: state.cookiesId,
        item_id: item.id,
        delivery_item_price: item.delivery_item_price,
        delivery_item_discount_price: item.delivery_item_discount_price,
        pickup_item_price: item.pickup_item_price,
        pickup_item_discount_price: item.pickup_item_discount_price,
        quantity: 1,
        order_type: orderType,
        ComparisonRate: UberEatsComparisonRate,
      };
      if (item.size_id) data.size_id = item.size_id;
      if (item.extra_id) data.extra_id = item.extra_id;
      //alert(UberEatsComparisonRate);
      const response = await cartapi.post("/cart", data);
      fetchCart();
    } catch (error) {
      console.error("Failed to add item to cart:", error);
    }
  };

  // Update Cart Info

  const updateOrderType = async (order_type) => {
    try {
      const response = await cartapi.post(`/cartOrderType`, {
        cookie_id: state.cookiesId,
        order_type,
      });
      fetchCart();
    } catch (error) {
      console.error("Failed to update cart item:", error);
    }
  };

  // Update item quantity in cart
  const updateItem = async (itemId, quantity) => {
    try {
      const response = await cartapi.put(`/cart/${itemId}`, {
        cookie_id: state.cookiesId,
        quantity,
      });
      fetchCart();
    } catch (error) {
      console.error("Failed to update cart item:", error);
    }
  };
  

  // Remove item from cart
  const removeItem = async (itemId) => {
    try {
      const response = await cartapi.delete("/cart", {
        data: { cookie_id: state.cookiesId, item_id: itemId },
      });
      fetchCart();
    } catch (error) {
      console.error("Failed to remove cart item:", error);
    }
  };

 const ApplyCouponCode = async (formdata) => {
    try {
      const response = await cartapi.post("/apply-coupon-code", formdata);
      fetchCart();
      return response.data; 
    } catch (error) {
      if (error.response) {
        return error.response.data; 
      } else {
        console.error("Error apply coupon code:", error);
        throw error; 
      }
    }
  };
  

  // Conditionally render based on loading or error state
  if (loading) {
    return <div>Loading...</div>; // Display a loading message or spinner
  }

  if (error) {
    return <div>{error}</div>; // Display the error message
  }

  return (
    <CartContext.Provider
      value={{
        cart: state.Cartitems,
        cookiesId: state.cookiesId,
        addItem,
        updateItem,
        removeItem,
        updateOrderType,
        ApplyCouponCode,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
