import React from 'react';
import { Spin } from 'antd'; // Assuming you're using Ant Design for the spinner
import './Loading.css'; // Optional: For additional styles

const Loading = ({ message = "Loading..." }) => {
  return (
    <div className="loading-container">
      <Spin size="large" />
      <p className="loading-message">{message}</p>
    </div>
  );
};

export default Loading;
