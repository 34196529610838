import React, { useState, useEffect, useContext } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import {
  Typography,
  Input,
  Button,
  Form,
  Row,
  Col,
  Space,
  Select,
  DatePicker,
  TimePicker,
  message,
} from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Font.css";
import aboutimg from "../../assets/images/video-cover-image-4.jpg";
import cuisineIco from "../../assets/images/cuisine.svg";
import mealsIco from "../../assets/images/cutlery.svg";
import { SubmitReservation } from "../../utils/api";
import moment from "moment";
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const Reservation = () => {
  const { businessInfo } = useContext(BusinessContext);
  const navigate = useNavigate();
  const [bookingId, setBookingId] = useState(null);
  const locationInfo =
    businessInfo?.businessInfo?.[0]?.location_info?.[0] || {};
  const AddressInfo = [
    locationInfo.house_number,
    locationInfo.street_name_en,
    locationInfo.city_en,
    locationInfo.postcode,
    locationInfo.country_en,
  ]
    .filter(Boolean) // Ensures only truthy values are included (avoids undefined or null)
    .join(" "); // Joins the address components with a space

  const [form] = Form.useForm();

  // Handle form submission and API request
  const handleSubmit = async (values) => {
    try {
      const formattedData = {
        ...values,
        booking_time: values.booking_time
          ? values.booking_time.format("HH:mm")
          : null, // Format to 'HH:mm'
      };
      const response = await SubmitReservation(formattedData); // Await the API call
      if (response) {
        message.success({
                          content: `Thank you for submitting your booking reservation with us. We will get back to you as soon as possible. Your booking number is: ${response.booking_id} for your reference.`,
                          duration: 5, 
                        });
        setBookingId(
          `Thank you for submitting your booking reservation with us. We will get back to you as soon as possible. Your booking number is: ${response.booking_id} for your reference.`
        );
        form.resetFields(); // Reset the form fields
      } else {
        message.error("Failed to submit reservation."); // Error message if response is null
      }
    } catch (error) {
      message.error("An error occurred while submitting the reservation.");
    }
  };

  if (businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.reservation_enable === 1) {
    navigate(`/`); // Avoid rendering anything if no data
  }

  return (
    <>
      {/* Banner Section */}
      <div className="menuBanner">
        <img
          src={aboutimg}
          alt="Menu Banner"
          style={{ width: "100%", height: "235px", objectFit: "cover" }}
        />
        <div className="innerBannerTitle">
          <Title level={2} style={{ color: "#fff", margin: "0" }}>
            Reservation
          </Title>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="custom_container contactSection">
        <Row gutter={32} className="contactWrap">
          {/* Left Side: Contact Form */}
          <Col xs={24} md={12}>
            <div className="contactForm">
              <Title level={3} className="customInnerHead">
                Make a Reservation
              </Title>
              {/* If the booking number is available, show it */}
              {bookingId && (
                <div>
                  <p>{bookingId}</p>
                </div>
              )}
              <Form layout="vertical" onFinish={handleSubmit} form={form}>
                <Row gutter={15}>
                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="booking_name"
                      label="Name"
                      rules={[
                        { required: true, message: "Please enter your name" },
                      ]}
                    >
                      <Input
                        placeholder="Name"
                        className="custom_input_field"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="booking_email"
                      label="Email Address"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        className="custom_input_field"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="booking_mobile"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Phone"
                        className="custom_input_field"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="booking_date"
                      label="Booking Date"
                      rules={[
                        { required: true, message: "Please select a date" },
                      ]}
                    >
                      <DatePicker
                        placeholder="Date"
                        style={{ width: "100%" }}
                        className="custom_input_field"
                        disabledDate={(current) => {
                          // Disable dates before today
                          return current && current < moment().startOf("day");
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="booking_time"
                      label="Booking Time"
                      rules={[
                        { required: true, message: "Please select a time" },
                      ]}
                    >
                      <TimePicker
                        placeholder="Time"
                        className="custom_input_field"
                        style={{ width: "100%" }}
                        format="HH:mm" // Display time in hours and minutes
                        showSecond={false} // Hide seconds
                        use12Hours={false} // Use 24-hour format
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="number_of_person"
                      label="Number of Guests"
                      initialValue="1"
                    >
                      <Select
                        placeholder="Guests"
                        style={{ width: "100%" }}
                        className="custom_input_field"
                      >
                        {[...Array(10).keys()].map((num) => (
                          <Option key={num + 1} value={num + 1}>
                            {num + 1}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* Special Comment Section */}
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                    <Form.Item
                      name="special_request"
                      label="Special Comment"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your special comment",
                        },
                        {
                          max: 500,
                          message:
                            "Special request cannot exceed 500 characters",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Special Comment"
                        className="custom_textarea_field"
                        rows={8}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="checkoutButton"
                >
                  Reserve Now
                </Button>
              </Form>
            </div>
          </Col>

          {/* Right Side: Contact Details and Social Media Links */}
          <Col xs={24} md={12}>
            <div className="contactDetails">
              <Title level={3} className="customInnerHead">
                Contact Information
              </Title>
              <Space direction="vertical" size="middle">
                <div className="contactItem">
                  <PhoneOutlined
                    style={{
                      color: "#1890ff",
                      fontSize: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <span>
                    <b>Phone:</b>{" "}
                    {businessInfo?.businessInfo?.[0]?.restaurant_phone || "N/A"}
                  </span>
                </div>
                <div className="contactItem">
                  <MailOutlined
                    style={{
                      color: "#1890ff",
                      fontSize: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <span>
                    <b>Email:</b>{" "}
                    {businessInfo?.businessInfo?.[0]?.email_address || "N/A"}
                  </span>
                </div>
                <div className="contactItem">
                  <EnvironmentOutlined
                    style={{
                      color: "#1890ff",
                      fontSize: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <span>
                    <b>Address:</b> {AddressInfo}
                  </span>
                </div>

                <div className="contactItem">
                  <img
                    src={cuisineIco}
                    style={{ width: "20px", marginRight: "8px" }}
                  />
                  <span>
                    <b>Cuisines:</b>{" "}
                    {businessInfo?.businessInfo?.[0]?.restaurant_cuisine_en ||
                      "N/A"}
                  </span>
                </div>

                <div className="contactItem">
                  <img
                    src={mealsIco}
                    style={{ width: "20px", marginRight: "8px" }}
                  />
                  <span>
                    <b>Meals:</b>{" "}
                    {businessInfo?.businessInfo?.[0]?.meals_available_en ||
                      "N/A"}
                  </span>
                </div>
              </Space>

              {/* Social Media Links */}
              <div className="socialFollowWrap">
                <Title level={4} style={{ marginTop: 0 }}>
                  Follow Us
                </Title>
                <Space size="large">
                  <a
                    href={
                      businessInfo?.businessInfo?.[0]?.social_facebook || ""
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookOutlined className="facebookIco" />
                  </a>
                  <a
                    href={
                      businessInfo?.businessInfo?.[0]?.social_instagram || ""
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramOutlined className="instagramIco" />
                  </a>
                  <a
                    href={businessInfo?.businessInfo?.[0]?.social_youtube || ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YoutubeOutlined className="youtubeIco" />
                  </a>
                </Space>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Reservation;
