// src/components/features/Menu/Menu.js
import React, { useContext, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Modal,
  Radio,
  Checkbox,
  Typography,
} from "antd";
import { BusinessContext } from "../../../context/BusinessContext";
import CartContext from "../../../context/CartContext";
import "../../../assets/css/Menu.css"; // Ensure this CSS file is created or updated
import { ShoppingCartOutlined } from "@ant-design/icons";

const { Title } = Typography;

const menuItems = [
  {
    id: 1,
    name: "Pizza Hawaii",
    price: 10.0,
    description:
      "with mozzarella, fresh tomatoes, olives with stone, onions and oregano.",
    image:
      "https://a.storyblok.com/f/177063/2532x1896/0fd9940347/new-fall-menu-2024.jpg", // Replace with actual image URL
  },
  {
    id: 2,
    name: "Burger",
    price: 8.0,
    description: "Juicy beef burger with lettuce and tomato.",
    image:
      "https://a.storyblok.com/f/177063/2532x1896/0fd9940347/new-fall-menu-2024.jpg", // Replace with actual image URL
  },

  {
    id: 3,
    name: "Pasta",
    price: 12.0,
    description: "Pasta with a rich and creamy sauce.",
    image:
      "https://a.storyblok.com/f/177063/2532x1896/0fd9940347/new-fall-menu-2024.jpg", // Replace with actual image URL
  },

  {
    id: 4,
    name: "Pasta2",
    price: 20.0,
    description: "Pasta with a rich and creamy sauce.",
    image:
      "https://a.storyblok.com/f/177063/2532x1896/0fd9940347/new-fall-menu-2024.jpg", // Replace with actual image URL
  },
];

const sizes = [
  { name: "Small", price: 8.0 },
  { name: "Medium", price: 10.0 },
  { name: "Large", price: 12.0 },
];

const toppings = [
  { name: "Pepperoni", price: 1.0 },
  { name: "Mushrooms", price: 1.0 },
  { name: "Onions", price: 1.0 },
  { name: "Extra Cheese", price: 1.5 },
];

const MyMenu = () => {
  const { dispatch } = useContext(CartContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSize, setSelectedSize] = useState(sizes[1]);
  const [selectedToppings, setSelectedToppings] = useState([]);

  const handleAddToCart = (item) => {
    dispatch({ type: "ADD_ITEM", payload: item });
  };

  const handleCustomize = (item) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const customizedItem = {
      ...selectedItem,
      size: selectedSize,
      toppings: selectedToppings,
    };
    dispatch({ type: "ADD_ITEM", payload: customizedItem });
    setIsModalVisible(false);
    resetModal();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    resetModal();
  };

  const resetModal = () => {
    setSelectedItem(null);
    setSelectedSize(sizes[1]);
    setSelectedToppings([]);
  };

  const { businessInfo, loading } = useContext(BusinessContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="custom_container">
      <div className="">
        <Title level={2} className="customHeading textCenter">
          {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.popular_food_en ||
            "Popular Categories"}
        </Title>
        <p className="food-items-description">
        Explore our handpicked selection of popular food items that are loved by our customers. 
        From savory snacks to delicious main courses, these items are the top choices that bring flavor to every meal.
        Browse through to find your new favorites!
      </p>
        <Row gutter={16}>
          {menuItems.map((item) => {
            // Define the Uber Eats Price (fixed for now, can be dynamic as per item if needed)
            const uberEatsPrice = 15.0;

            // Calculate the Order Direct Price with 30% off
            const orderDirectPrice = uberEatsPrice - uberEatsPrice * 0.3;

            return (
              <Col span={6} key={item.id}>
                <Card style={{ height: "100%" }} className="categoryWrap">
                  <div className="categoryImgWrap">
                    <img alt={item.name} src={item.image} />
                  </div>
                  <div className="categoryInfoWrap">
                    <Title level={4} className="categoryName">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 0.5H8C8.82843 0.5 9.5 1.17157 9.5 2V8C9.5 8.82843 8.82843 9.5 8 9.5H2C1.17157 9.5 0.5 8.82843 0.5 8V2C0.5 1.17157 1.17157 0.5 2 0.5Z"
                          fill="white"
                          stroke="#008000"
                        ></path>
                        <circle cx="5" cy="5" r="2" fill="#008000"></circle>
                      </svg>
                      {item.name}{" "}
                      <div
                        className="categoryRating"
                        style={{ marginLeft: "5px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          className="bi bi-star-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        4.3
                      </div>
                    </Title>
                    <p className="categoryDescription">{item.description}</p>
                    <div className="PriceDescription">
                      <p className="platformPrice uberEats">
                        <span className="platformName">Uber Eats Price:</span>
                        <span className="price">
                          £{uberEatsPrice.toFixed(2)}
                        </span>
                      </p>
                      <p className="platformPrice deliveroo">
                        <span className="platformName">Our Price:</span>
                        <span className="price">
                          £{orderDirectPrice.toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="categoryPriceInfo">
                      <p className="categoryPrice">
                        £{orderDirectPrice.toFixed(2)}{" "}
                        <span className="discountPricemen">(30% OFF)</span>
                      </p>
                      {item.name === "Pizza Hawaii" ? (
                        <Button
                          type="primary"
                          className="cartBtn"
                          onClick={() => handleCustomize(item)}
                        >
                          Customize
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="cartBtn"
                          onClick={() => handleAddToCart(item)}
                        >
                          <ShoppingCartOutlined /> Add to Cart
                        </Button>
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>

        <Modal
          title={""}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="customize-modal"
        >
          <div className="categoryImgModal">
            <img
              src="https://a.storyblok.com/f/177063/2532x1896/0fd9940347/new-fall-menu-2024.jpg"
              alt="Pizza Hawaii"
            />
          </div>
          <div className="categoryModalBody">
            <Title level={3} className="categoryName">
              Customize Your {selectedItem?.name}
            </Title>
            <p className="categoryPriceModal">&pound;12.00</p>
            <p className="categoryDiscriptionModal">
              Penne in tomato sauce with chilies and garlic
            </p>
          </div>
          <hr className="customHr" />
          <div className="categoryModalSizeWrap">
            <h3>Select Size</h3>
            {sizes.map((size) => (
              <div key={size.name} className="size-option">
                <label className="sizeLabel">
                  <Radio
                    value={size}
                    checked={selectedSize.name === size.name}
                    onChange={() => setSelectedSize(size)}
                  />
                  <span>{size.name}</span>
                </label>
                <span className="price">&pound;{size.price}.00</span>
              </div>
            ))}

            <Title level={5} className="toppingHeading">
              Choose toppings
            </Title>
            <p className="toppingSubtext">Choose up to 50 additional items</p>
            {toppings.map((topping) => (
              <div key={topping.name} className="size-option">
                <label className="sizeLabel">
                  <Checkbox value={topping.name} />
                  <span className="sizeCheckBox"> {topping.name}</span>
                </label>
                <span className="price">&pound;{topping.price}.00</span>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MyMenu;
