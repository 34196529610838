import React, { useContext, useState, useEffect } from "react";
import { Card, Carousel, Typography, Spin, Button, Modal } from "antd";
import { BusinessContext } from "../../context/BusinessContext";
import { fetchResServices } from "../../utils/api";
import { Link } from "react-router-dom"; // Import Link for routing
import "./OurServicesList.css";
const { Title } = Typography;

const OurServicesList = () => {
  const [ResServiceData, setResServiceData] = useState([]);
  const { businessInfo, loading } = useContext(BusinessContext);
  const [isModalVisibleService, setIsModalVisibleService] = useState(false);
  const [modalContentService, setModalContentService] = useState(""); // To store modal content
  const [modalTitleService, setModalTitleService] = useState(""); // To store modal title
  const [modalImageService, setModalImageService] = useState(""); // To store modal image

  useEffect(() => {
    const getResServices = async () => {
      try {
        const fetchResServicesData = await fetchResServices();
        if (fetchResServicesData) {
          setResServiceData(fetchResServicesData.data || []);
        } else {
          console.error("Failed to fetch restaurant services");
        }
      } catch (error) {
        console.error("Error fetching restaurant services:", error);
      }
    };
    getResServices();
  }, []);

  const handleShowMoreService = (service) => {
    setModalTitleService(service.page_title_en); // Set the title for the modal
    setModalImageService(service.page_image); // Set the image for the modal
    setModalContentService(service.page_content_en); // Set the full content for the modal
    setIsModalVisibleService(true);  // Show the modal
  };

  const handleModalCancelService = () => {
    setIsModalVisibleService(false); // Close the modal
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="aboutSection">
      <div className="custom_container">
        <Title level={2} className="customHeading textCenter">
        {businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.res_service_en ||
            `Our Restaurant Service`}
        </Title>
        <p className="popular-categories-description">
        {businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.res_service_desc_en ||
            `We pride ourselves on delivering an exceptional dining experience that goes beyond just great food. Whether you're planning an intimate dinner, a grand celebration, or a casual gathering, our restaurant services are designed to cater to your every need.`}
        </p>

        <div className="ourservices-carousel-wrapper">
          <Carousel
            dots={false}
            arrows={true}
            slidesToShow={3}
            slidesToScroll={1}
            autoplay
            autoplaySpeed={3000}
            centerMode={true}
            centerPadding="10px"
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {ResServiceData.map((service) => {
              const truncatedDescription = service.page_content_en.length > 130 
                ? service.page_content_en.substring(0, 130) + "..." 
                : service.page_content_en;

              return (
                <div key={service.id} className="service-card-wrapper">
                  <div className="service-products-card">
                    <Card
                      hoverable
                      cover={<img alt={service.page_title_en} src={service.page_image} />}
                    >
                      <h5 className="service-title">{service.page_title_en}</h5>
                      <p className="service-description">
                        {truncatedDescription}
                        {service.page_content_en.length > 150 && (
                          <Button 
                            type="link" 
                            onClick={() => handleShowMoreService(service)}
                          >
                            Show more
                          </Button>
                        )}
                      </p>
                    </Card>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>

        {/* Modal for showing full description */}
        <Modal
          title={modalTitleService}
          visible={isModalVisibleService}
          onCancel={handleModalCancelService}
          footer={null}
        >
          <div className="modal-content">
            <img 
              src={modalImageService} 
              alt={modalTitleService} 
              className="modal-image" 
              style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} 
            />
            <p>{modalContentService}</p>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default OurServicesList;
