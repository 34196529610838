// src/App.js
import React from "react";
import { CartProvider } from "./context/CartContext";
import { BusinessProvider } from "./context/BusinessContext";
import AppRouter from "./router/AppRouter";
import "./assets/css/styles.css"; // Import global styles


const App = () => {
  return (
    <BusinessProvider>
      {" "}
      {/* Wrap BusinessProvider around CartProvider */}
      <CartProvider>
        <AppRouter />
      </CartProvider>
    </BusinessProvider>
  );
};

export default App;
