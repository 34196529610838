import React, { useState, useEffect } from "react";
import { fetchResNotice } from "../../utils/api";
import "./topbarSlide.css"; // Include this for styling

const Ticker = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [TopMarquee, setTopMarquee] = useState([]);

  useEffect(() => {
      const getResTopMarquee = async () => {
        try {
          const fetchResNoticeData = await fetchResNotice();
          if (fetchResNoticeData) {
            setTopMarquee(fetchResNoticeData.data || []);
          } else {
            console.error("Failed to fetch restaurant marquee");
          }
        } catch (error) {
          console.error("Error fetching restaurant marquee:", error);
        }
      };
      getResTopMarquee();
    }, []);

  return (
    <div className="topbar bg-main">
      <div className="container w_1920 pd0">
        <div
          className="ticker-title"
          style={{
            animationPlayState: isHovered ? "paused" : "running", // Pause animation on hover
          }}
          onMouseEnter={() => setIsHovered(true)} // Pause on hover
          onMouseLeave={() => setIsHovered(false)} // Resume when not hovering
        >
          {TopMarquee.map((message, index) => (
            <p key={index} className="flex items-center fz-13 fw-500 uppercase white">
              <DoubleQuoteLeftIcon /> {/* Left double quote */}
              {message.content_en}
              <DoubleQuoteRightIcon /> {/* Right double quote */}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

const DoubleQuoteLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="icon double-quote-left-icon"
  >
    <path d="M9 10a4 4 0 0 1-4 4v3h3v-3a1 1 0 0 0-1-1h-2a4 4 0 0 1 4-4V7H9v3Z" />
    <path d="M16 10a4 4 0 0 1-4 4v3h3v-3a1 1 0 0 0-1-1h-2a4 4 0 0 1 4-4V7h-1v3Z" />
  </svg>
);

const DoubleQuoteRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="icon double-quote-right-icon"
  >
    <path d="M15 14a4 4 0 0 1-4-4V7h3v3a1 1 0 0 0 1 1h2a4 4 0 0 1-4 4v3h1v-3Z" />
    <path d="M8 14a4 4 0 0 1-4-4V7h3v3a1 1 0 0 0 1 1h2a4 4 0 0 1-4 4v3h1v-3Z" />
  </svg>
);

export default Ticker;
