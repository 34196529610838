// src/components/pages/Order.js
import React from 'react';
import Checkout from '../features/Cart/Checkout';
import "../features/Cart/Checkout.css";
import { Elements } from '@stripe/react-stripe-js'; // Import Elements
import { loadStripe } from '@stripe/stripe-js'; // Import loadStripe

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_live_51Pt6whP515ZOfhlDHjKVowFWRCyLVWTnUy91KPOrfr5WtGynS1mcELAmtbNxSJmZwZOceeQeH87g1l0VTqbgvIUO00YKrMMIDF');

const Order = () => {
  return (
    <div className='checkoutSection'>
      {/* Wrap Checkout component with Elements */}
      <Elements stripe={stripePromise}>
        <Checkout />
      </Elements>
    </div>
  );
};

export default Order;
