import React, { useState, useEffect, useContext } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import { Carousel, Typography, Rate, Row, Col, Button, Modal } from "antd";
import "../About/About.css";
import aboutimg from "../../assets/images/video-cover-image-4.jpg";

const { Title } = Typography;

const AboutUs = () => {
  const { businessInfo } = useContext(BusinessContext);
  const [isYoutubeModalVisible, setIsYoutubeModalVisible] = useState(false);

  const showYoutubeModal = () => {
    setIsYoutubeModalVisible(true);
  };

  const handleYoutubeCancel = () => {
    setIsYoutubeModalVisible(false);
  };
  return (
    <div className="aboutSection">
      <div className="custom_container">
        <Row className="aboutInnerWrap">
          <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
            <div className="aboutLeftContent">
              <Title level={2} className="customHeading">
                {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                  ?.res_defaultTitle_en || "Know more about us"}
              </Title>
              <p className="aboutDetails">
                {businessInfo.businessInfo[0]?.restaurant_description_en}
              </p>
              <Title level={1} className="subHeading">
                {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                  ?.res_sub_title_en || "Live, Eat, Breath Indian. Enjoy!"}
              </Title>
              <Button
                className="aboutBtn"
                style={{ marginBottom: "40px", marginTop: "20px" }}
              >
                <div className="playBtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 60 60"
                    className="playBtnSvg"
                  >
                    <path
                      fill="currentColor"
                      d="M23.987 22.973c0-1.109.792-1.604 1.775-1.103L39.1 28.667c.98.5.984 1.308 0 1.81l-13.338 6.796c-.98.5-1.775.004-1.775-1.103V22.973z"
                    ></path>
                  </svg>
                </div>
                {businessInfo?.businessInfo?.[0]?.social_youtube && (
                  <span className="watchLink" onClick={showYoutubeModal}>
                    {businessInfo?.businessInfo?.[0]?.homelng?.[0]
                      ?.watch_video_en || "Watch Video"}
                  </span>
                )}
              </Button>
              
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
            <div className="aboutBanner">
              <img
                src={
                  businessInfo.businessInfo[0]?.restaurant_home_about_img ||
                  aboutimg
                }
                alt={businessInfo.businessInfo[0]?.restaurant_name_en || "PDQ2"}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        title="Watch Our Video"
        visible={isYoutubeModalVisible}
        onCancel={handleYoutubeCancel}
        footer={null}
        width={800}
      >
        <iframe
          width="100%"
          height="450"
          src={
            businessInfo.businessInfo[0]?.social_youtube ||
            "https://www.youtube.com/embed/vwswg0AuqKM?si=NdyPMuX-vQRltt51"
          }
          title={businessInfo.businessInfo[0]?.restaurant_name_en || "PDQ2"}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Modal>
    </div>
  );
};

export default AboutUs;
