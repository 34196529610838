import React, { useContext } from "react";
import { Typography } from "antd";
import { BusinessContext } from "../../context/BusinessContext";
import "../../assets/css/Home.css";
import "../../assets/css/Font.css";

const { Title } = Typography;

const DirectOrderSearch = () => {
  const { businessInfo, loading } = useContext(BusinessContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="search-container">
        <Title>
          {businessInfo?.businessInfo?.[0]?.homelng?.[0]
            ?.collection_delivery_get_en ?? `Collection or Delivery, Get 30% OFF When you Order Direct!` }
        </Title>

        <Title level={2}>
          {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.we_back_to_serve_en ?? "Delicious Food Delivered Straight to Your Door in Minutes! We’re back to serve you directly with the food you love."}
        </Title>

        <Title level={3}>
          {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.click_here_to_place_order_en ?? "✨ Click below to place your order now!"}
        </Title>
        <div className="directOrder_search">
          <div className="directOrder-button-container">
            
          {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.delivery_type_enable === 0 ? (<a href="/menu" className="directOrder-btn directOrder-btn-delivery">
            <svg viewBox="0 0 24 24" height="25" className="directOrder-icon" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.401 9.602c.281.281.666.439 1.062.439h.039a1.48 1.48 0 0 0 1.075-.496l3.157-3.507a1.505 1.505 0 0 0-.055-2.071L8.098 2.385A1.547 1.547 0 0 0 7 1.947c-.41.01-.802.191-1.076.495l-3.16 3.512a1.502 1.502 0 0 0 .054 2.066l1.583 1.582Zm6.321 2.132 1.451-1.659a8.276 8.276 0 0 0 4.655 1.42h.672a1.5 1.5 0 1 0 0-3h-.672a5.363 5.363 0 0 1-3.767-1.561 1.407 1.407 0 0 0-1.111-.438 1.5 1.5 0 0 0-1.079.511l-3.5 4a1.5 1.5 0 0 0 .3 2.236l.664.442a5.261 5.261 0 0 1 2.2 5.484 1.5 1.5 0 1 0 2.928.651 8.285 8.285 0 0 0-2.741-8.086ZM22 18.495a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-17 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm3.125-14.5a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z"
                  ></path>
                </svg>{" "}
              <span> {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.delivery_en ?? "Delivery"}</span>
            </a>):''}
            
            {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.pickup_type_enable === 0 ? (<a href="/menu" className="directOrder-btn directOrder-btn-collection">
            <svg viewBox="0 0 24 24" className="directOrder-icon" height="25" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.869 3.284a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm2.109 9.162a8.519 8.519 0 0 1-2.418-1.266l-.288-.218a.25.25 0 0 0-.4.2v1.804a.993.993 0 0 0 .355.754 17.2 17.2 0 0 1 4.568 7.518 1.5 1.5 0 1 1-2.846.952 16.622 16.622 0 0 0-2.416-4.927.25.25 0 0 0-.433.081 17.332 17.332 0 0 1-2.56 5.309 1.5 1.5 0 1 1-2.344-1.873 12.745 12.745 0 0 0 2.213-4.7c.308-1.385.463-2.799.463-4.217v-1.357a.25.25 0 0 0-.38-.214 3.413 3.413 0 0 0-1.622 2.944 1.5 1.5 0 0 1-1.5 1.426 1.5 1.5 0 0 1-1.5-1.613 6.32 6.32 0 0 1 3.511-5.571 5.7 5.7 0 0 1 5.963.54l1.016.762a5.514 5.514 0 0 0 1.564.82l1.105.367a1.551 1.551 0 0 1 1.083 1.673 1.5 1.5 0 0 1-1.958 1.198l-1.176-.392Z"
                  ></path>
                </svg>{" "}
              
              <span>{businessInfo?.businessInfo?.[0]?.homelng?.[0]?.collection_en ?? "Collection"}</span>
            </a>):''}
            {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.reservation_enable === 0 ? (<a href="/reservation" className="directOrder-btn directOrder-btn-table">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" height="30" className="directOrder-icon">
  <circle cx="12" cy="5" r="2" fill="currentColor" />
  <rect x="6" y="10" width="12" height="4" fill="currentColor" rx="1" />
  <line x1="8" y1="14" x2="8" y2="20" stroke="currentColor" strokeWidth="2" />
  <line x1="16" y1="14" x2="16" y2="20" stroke="currentColor" strokeWidth="2" />
  <rect x="4" y="10" width="2" height="4" fill="currentColor" rx="1" />
  <rect x="18" y="10" width="2" height="4" fill="currentColor" rx="1" />
</svg><span>{businessInfo?.businessInfo?.[0]?.homelng?.[0]?.reservation_en ?? "Book a Table"}</span>
            </a>):''}
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectOrderSearch;
