import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Radio, Checkbox,
  Modal,
  Dropdown,
  Typography,
  Space,
  message,
  Spin,
} from "antd";
import CartContext from "../../../context/CartContext";
import { BusinessContext } from "../../../context/BusinessContext";
import { fetchCategoriesAndFoodItems } from "../../../utils/api";
import "../../../assets/css/Menu.css";
import "./foodItems.css";
import {
  MenuOutlined,
  ShoppingCartOutlined,
  DownOutlined,
} from "@ant-design/icons";
import dealimg from "../../../assets/images/deal.png";
import Loader from "../../Loader/";
const { Title } = Typography;


const sizes = [
  { name: 'Small', price: 8.00 },
  { name: 'Medium', price: 10.00 },
  { name: 'Large', price: 12.00 },
];

const toppings = [
  { name: 'Pepperoni', price: 1.00 },
  { name: 'Mushrooms', price: 1.00 },
  { name: 'Onions', price: 1.00 },
  { name: 'Extra Cheese', price: 1.50 },
];

const FoodItems = () => {
  const [menuData, setMenuData] = useState([]);
  const { dispatch } = useContext(CartContext);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSize, setSelectedSize] = useState(sizes[1]);
  const [selectedToppings, setSelectedToppings] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const { addItem } = useContext(CartContext);
  const [loadingItems, setLoadingItems] = useState({});

  const handleAddToCart = async (item, UberEatsComparisonRate) => {
    setLoadingItems((prev) => ({ ...prev, [item.id]: true })); // Set loading for specific item
    try {
      // Fetch order type from local storage or default to 0
      const orderType = localStorage.getItem("order_type") || 0;
      await addItem(item, orderType, UberEatsComparisonRate); // Pass the order type to addItem
      message.success(`${item.food_item_name_en} added to cart!`);
    } catch (error) {
      message.error(`Failed to add ${item.food_item_name_en} to cart. Try again!`);
    } finally {
      setLoadingItems((prev) => ({ ...prev, [item.id]: false })); // Reset loading for specific item
    }
  };

const handleCustomize = (item) => {
  setSelectedItem(item);
  setIsModalVisible(true);
};

const handleOk = () => {
  const customizedItem = {
    ...selectedItem,
    size: selectedSize,
    toppings: selectedToppings,
  };
  dispatch({ type: 'ADD_ITEM', payload: customizedItem });
  setIsModalVisible(false);
  resetModal();
};

const handleCancel = () => {
  setIsModalVisible(false);
  resetModal();
};

const resetModal = () => {
  setSelectedItem(null);
  setSelectedSize(sizes[1]);
  setSelectedToppings([]);
};

const [Menuloading, setMenuLoading] = useState(false);

  const getCategoriesAndFoodItems = async () => {
    setMenuLoading(true);
    try {
      const fetchedData = await fetchCategoriesAndFoodItems();
      if (fetchedData) {
        setMenuData(fetchedData.data || []);
        //console.log(fetchedData.data);
      } else {
        console.error("Failed to fetch categories and food items");
      }
    } catch (error) {
      console.error("Error fetching categories and food items:", error);
    }
    finally {
      setMenuLoading(false); // Stop loading
    }
  };
 const { businessInfo } = useContext(BusinessContext);
 const comprisionwebs = businessInfo?.businessInfo?.[0]?.comprisionweb || [];

  useEffect(() => {
    getCategoriesAndFoodItems();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSelectedCategory(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionRefs.current.forEach((ref) => ref && observer.observe(ref));

    return () => {
      window.removeEventListener("resize", handleResize);
      sectionRefs.current.forEach((ref) => ref && observer.unobserve(ref));
    };
  }, []);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    const categoryElement = document.getElementById(categoryId);
    if (categoryElement) {
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

    let UberEatsComparisonRate = 0;
    const uberEatsData = comprisionwebs.find(
      (comprisionweb) => comprisionweb.company_name === "Uber Eats"
    );
    if (uberEatsData) {
      UberEatsComparisonRate = parseFloat(uberEatsData.price_discount) / 100 || 0;
    }

  const categoryMenu = (
    <div>
      {menuData.map((category) => (
        <Button
          key={category.id}
          className={`categoryBtn ${
            selectedCategory === category.id ? "active" : ""
          }`}
          onClick={() => handleCategoryClick(category.id)}
        >
          {category.menu_cat_name_en}
        </Button>
      ))}
    </div>
  );
  if (Menuloading) {
    return <Loader />;
  }

  return (
    <div className="FoodInnerMenuWrap">
      <div className="categories-container">
        {isMobile ? (
          <Dropdown overlay={categoryMenu} trigger={["click"]}>
            <Button className="category-dropdown-btn">
              <MenuOutlined /> Select Category
            </Button>
          </Dropdown>
        ) : (
          <div className="category-list-horizontal">
            <div className="menuCateLeft">
              {menuData.slice(0, 7).map((category, index) => (
                <Button
                  key={category.id}
                  className={`categoryBtn ${
                    selectedCategory === category.id ? "active" : ""
                  }`}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.menu_cat_name_en}
                </Button>
              ))}
            </div>

            {/* Render the dropdown if there are more than 8 categories */}
            {menuData.length > 7 && (
              <div className="menuCateRight">
                <Dropdown
                  menu={{
                    items: menuData.slice(7).map((category) => ({
                      key: category.id,
                      label: (
                        <Button style={{ width: "100%" }}
                          className={`categoryBtn ${
                            selectedCategory === category.id ? "active" : ""
                          }`}
                          onClick={() => handleCategoryClick(category.id)}
                        >
                          {category.menu_cat_name_en}
                        </Button>
                      ),
                    })),
                  }}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      More ({menuData.length - 7})
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="custom_container">
{/*         <Row gutter={16}>
          <Col xs={24} sm={12} md={8}>
            <div className="foodItemOfferSection">
              <div className="foodItemOfferBox">
                <div className="dealImg">
                  <img src={dealimg} alt="Deal" />
                </div>
                <div className="dealOffer">Collection or Delivery, Get 30% OFF</div>
              </div>
            </div>
          </Col>
        </Row> */}

        <div className="foodItemsSection">
          {menuData.map((category, index) => (
            <div
              key={category.id}
              id={category.id}
              ref={(el) => (sectionRefs.current[index] = el)}
            >
              <Title level={3} className="categoryTitle">
                {category.menu_cat_name_en}
              </Title>
              <Row gutter={16} className="foodItemMenuBox">
                {category.food_items?.map((item) => {
                  const deliveryPrice = parseFloat(
                    item.delivery_item_price || "0"
                  );
                  const deliveryDiscountPercentage = parseFloat(
                    item.delivery_item_discount_price || "0"
                  );

                  if (item.delivery_item_price !== "") {
                    const discountedDeliveryPrice =
                      deliveryPrice -
                      (deliveryPrice * deliveryDiscountPercentage) / 100;
                  /*   console.log(
                      `Discounted Delivery Price: £${discountedDeliveryPrice.toFixed(
                        2
                      )}`
                    ); */
                  }

                  const pickupPrice = parseFloat(item.pickup_item_price || "0");
                  const pickupDiscountPercentage = parseFloat(
                    item.pickup_item_discount_price || "0"
                  );

                  if (item.pickup_item_price !== "") {
                    const discountedPickupPrice =
                      pickupPrice -
                      (pickupPrice * pickupDiscountPercentage) / 100;
                /*     console.log(
                      `Discounted Collection Price: £${discountedPickupPrice.toFixed(
                        2
                      )}`
                    ); */
                  }

                  return (
                    <Col xs={24} sm={12} md={8} lg={6} key={item.id}>
                      <Card style={{ height: "100%" }} className="categoryWrap">
                      {item.food_item_img && (
                        <div className="categoryImgWrap">
                          <img
                            alt={item.food_item_name_en}
                            src={item.food_item_img}
                            className="categoryImg"
                          />
                        </div>
                      )}
                        <div className="categoryInfoWrap">
                          <Title level={4} className="categoryName">
                            {item.food_item_name_en}{" "}
                            
                          </Title>
                          <div className="categoryDescription">
                            {item.food_item_desc_en ||
                              ""}
                          </div>

                          <div className="PriceDescription">
                          

                            {item.delivery_item_price && (
                              <p className="platformPrice delivery">
                                <span className="platformName">
                                Direct Order Price (<svg viewBox="0 0 24 24" height="15" className="directOrder-icon" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.401 9.602c.281.281.666.439 1.062.439h.039a1.48 1.48 0 0 0 1.075-.496l3.157-3.507a1.505 1.505 0 0 0-.055-2.071L8.098 2.385A1.547 1.547 0 0 0 7 1.947c-.41.01-.802.191-1.076.495l-3.16 3.512a1.502 1.502 0 0 0 .054 2.066l1.583 1.582Zm6.321 2.132 1.451-1.659a8.276 8.276 0 0 0 4.655 1.42h.672a1.5 1.5 0 1 0 0-3h-.672a5.363 5.363 0 0 1-3.767-1.561 1.407 1.407 0 0 0-1.111-.438 1.5 1.5 0 0 0-1.079.511l-3.5 4a1.5 1.5 0 0 0 .3 2.236l.664.442a5.261 5.261 0 0 1 2.2 5.484 1.5 1.5 0 1 0 2.928.651 8.285 8.285 0 0 0-2.741-8.086ZM22 18.495a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-17 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm3.125-14.5a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z"
                  ></path>
                </svg>):
                                </span>
                                {item.delivery_item_discount_price ? (
                                  <>
                                    {/* Show discounted price with original price crossed out */}
                                    <span
                                      className="originalPrice"
                                      style={{
                                        textDecoration: "line-through",
                                        marginRight: "8px",
                                      }}
                                    >
                                      £
                                      {parseFloat(
                                        item.delivery_item_price
                                      ).toFixed(2)}
                                    </span>
                                    <span className="discountedPrice">
                                      £
                                      {(
                                        parseFloat(item.delivery_item_price) -
                                        (parseFloat(item.delivery_item_price) *
                                          parseFloat(
                                            item.delivery_item_discount_price
                                          )) /
                                          100
                                      ).toFixed(2)}
                                    </span>
                                  </>
                                ) : (
                                  /* Show only the current price if no discount */
                                  <span className="currentPrice">
                                    £
                                    {parseFloat(
                                      item.delivery_item_price
                                    ).toFixed(2)}
                                  </span>
                                )}
                              </p>
                            )}

                            {item.pickup_item_price && (
                              <p className="platformPrice pickup">
                                <span className="platformName">
                                Direct Order Price (<svg viewBox="0 0 24 24" className="directOrder-icon" height="15" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.869 3.284a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm2.109 9.162a8.519 8.519 0 0 1-2.418-1.266l-.288-.218a.25.25 0 0 0-.4.2v1.804a.993.993 0 0 0 .355.754 17.2 17.2 0 0 1 4.568 7.518 1.5 1.5 0 1 1-2.846.952 16.622 16.622 0 0 0-2.416-4.927.25.25 0 0 0-.433.081 17.332 17.332 0 0 1-2.56 5.309 1.5 1.5 0 1 1-2.344-1.873 12.745 12.745 0 0 0 2.213-4.7c.308-1.385.463-2.799.463-4.217v-1.357a.25.25 0 0 0-.38-.214 3.413 3.413 0 0 0-1.622 2.944 1.5 1.5 0 0 1-1.5 1.426 1.5 1.5 0 0 1-1.5-1.613 6.32 6.32 0 0 1 3.511-5.571 5.7 5.7 0 0 1 5.963.54l1.016.762a5.514 5.514 0 0 0 1.564.82l1.105.367a1.551 1.551 0 0 1 1.083 1.673 1.5 1.5 0 0 1-1.958 1.198l-1.176-.392Z"
                  ></path>
                </svg>):
                                </span>
                                {item.pickup_item_discount_price ? (
                                  <>
                                    {/* Show discounted price with original price crossed out */}
                                    <span
                                      className="originalPrice"
                                      style={{
                                        textDecoration: "line-through",
                                        marginRight: "8px",
                                      }}
                                    >
                                      £
                                      {parseFloat(
                                        item.pickup_item_price
                                      ).toFixed(2)}
                                    </span>
                                    <span className="discountedPrice">
                                      £
                                      {(
                                        parseFloat(item.pickup_item_price) -
                                        (parseFloat(item.pickup_item_price) *
                                          parseFloat(
                                            item.pickup_item_discount_price
                                          )) /
                                          100
                                      ).toFixed(2)}
                                    </span>
                                  </>
                                ) : (
                                  /* Show only the current price if no discount */
                                  <span className="currentPrice">
                                    £
                                    {parseFloat(item.pickup_item_price).toFixed(
                                      2
                                    )}
                                  </span>
                                )}
                              </p>
                            )}
                              

                              {(() => {
  // Calculate the total saving (sum of all savings)
  let totalSaving = 0; // Initialize saving amount
  const deliveryItemPrice = parseFloat(item.delivery_item_price) || 0;
  totalSaving = deliveryItemPrice * UberEatsComparisonRate;

  return (
  <div className="comparisonList">
    {comprisionwebs.map((comprisionweb, idx) => {
      const discountComparisonRate =
        parseFloat(comprisionweb.price_discount) / 100 || 0; // Convert discount to decimal
      const deliveryItemPrice = parseFloat(item.delivery_item_price) || 0; // Ensure item price is valid
      const discountedAmount = deliveryItemPrice * discountComparisonRate; // Calculate the discount amount
      const finalFoodDeliveryPrice = deliveryItemPrice + discountedAmount; // Calculate the final price after discount
      const finalSavingDeliveryPrice = finalFoodDeliveryPrice - deliveryItemPrice; // Total saving

      return (
        <div key={idx} className="comparisonItem">
          <span
            className="platformName"
            style={{ color: comprisionweb.platform_color }}
          >
            {comprisionweb.company_name}:
          </span>
          <span
            className="price"
            style={{ color: comprisionweb.platform_color }}
          >
            £{finalFoodDeliveryPrice.toFixed(2)} 
          </span>
          <span className="discount">
            ({comprisionweb.price_discount}%)
          </span>
          <small className="saving">
            Direct Order Savings : <span>£{finalSavingDeliveryPrice.toFixed(2)}</span>
          </small>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            className="priceArrow"
          >
            <path d="M12 16l-4-4h8l-4 4z" />
          </svg>
        </div>
      );
    })}
  </div>
  );
})()}



                          </div>

                          <div className="categoryPriceInfo">
                            {businessInfo?.businessInfo?.[0]?.pdq2_agreed_discount && businessInfo?.businessInfo?.[0]?.pdq2_agreed_discount > 0 && (
                              <p className="categoryPrice">
                                <span className="discountPricemen">
                                  ({businessInfo?.businessInfo?.[0]?.pdq2_agreed_discount}% OFF)
                                </span>
                              </p>
                            )}

                            {item.size_count > 0 ? (
                              <Button type="primary"  onClick={() => handleCustomize(item)} className="cartBtn">
                                Customize
                              </Button>
                            ) : (
                              
                              <Button
      type="primary"
      className="cartBtn"
      icon={loadingItems[item.id] ? <Spin size="small" /> : <ShoppingCartOutlined />}
      onClick={() => handleAddToCart(item, UberEatsComparisonRate)}
      disabled={loadingItems[item.id]} // Disable button during API call for this item
    >
      {loadingItems[item.id] ? "Adding..." : "Add to Cart"}
    </Button>
                              
                            )}

                            {/* <div
                              className="categoryRating"
                              style={{ marginLeft: "5px" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="currentColor"
                                class="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              {parseFloat(item.food_rating || 0).toFixed(1)}
                            </div> */}
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for customization */}

 <Modal
          title={""}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="customize-modal"
        >
           {selectedItem?.food_item_img && (
                        <div className="categoryImgModal">
                          <img
                            alt={selectedItem?.food_item_name_en}
                            src={selectedItem?.food_item_img}
                            className="categoryImg"
                          />
                        </div>
                      )}
         
          <div className="categoryModalBody">
            <Title level={3} className="categoryName">
             {selectedItem?.food_item_name_en}
            </Title>
            <div className="PriceDescription">
                            {selectedItem?.deliveroo_price &&
                              !isNaN(parseFloat(selectedItem?.deliveroo_price)) && (
                                <p className="platformPrice uberEats">
                                  <span className="platformName">
                                    Deliveroo Price:
                                  </span>
                                  <span className="price">
                                    £
                                    {parseFloat(selectedItem?.deliveroo_price).toFixed(
                                      2
                                    )}
                                  </span>
                                </p>
                              )}

                            {selectedItem?.uberEat_price &&
                              !isNaN(parseFloat(selectedItem?.uberEat_price)) && (
                                <p className="platformPrice uberEats">
                                  <span className="platformName">
                                    Uber Eats Price:
                                  </span>
                                  <span className="price">
                                    £{parseFloat(selectedItem?.uberEat_price).toFixed(2)}
                                  </span>
                                </p>
                              )}

                            {selectedItem?.delivery_item_price && (
                              <p className="platformPrice delivery">
                                <span className="platformName">
                                  Direct Order Price (<svg viewBox="0 0 24 24" height="15" className="directOrder-icon" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.401 9.602c.281.281.666.439 1.062.439h.039a1.48 1.48 0 0 0 1.075-.496l3.157-3.507a1.505 1.505 0 0 0-.055-2.071L8.098 2.385A1.547 1.547 0 0 0 7 1.947c-.41.01-.802.191-1.076.495l-3.16 3.512a1.502 1.502 0 0 0 .054 2.066l1.583 1.582Zm6.321 2.132 1.451-1.659a8.276 8.276 0 0 0 4.655 1.42h.672a1.5 1.5 0 1 0 0-3h-.672a5.363 5.363 0 0 1-3.767-1.561 1.407 1.407 0 0 0-1.111-.438 1.5 1.5 0 0 0-1.079.511l-3.5 4a1.5 1.5 0 0 0 .3 2.236l.664.442a5.261 5.261 0 0 1 2.2 5.484 1.5 1.5 0 1 0 2.928.651 8.285 8.285 0 0 0-2.741-8.086ZM22 18.495a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-17 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm3.125-14.5a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z"
                  ></path>
                </svg>):
                                </span>
                                {selectedItem?.delivery_item_discount_price ? (
                                  <>
                                    {/* Show discounted price with original price crossed out */}
                                    <span
                                      className="originalPrice"
                                      style={{
                                        textDecoration: "line-through",
                                        marginRight: "8px",
                                      }}
                                    >
                                      £
                                      {parseFloat(
                                        selectedItem?.delivery_item_price
                                      ).toFixed(2)}
                                    </span>
                                    <span className="discountedPrice">
                                      £
                                      {(
                                        parseFloat(selectedItem?.delivery_item_price) -
                                        (parseFloat(selectedItem?.delivery_item_price) *
                                          parseFloat(
                                            selectedItem?.delivery_item_discount_price
                                          )) /
                                          100
                                      ).toFixed(2)}
                                    </span>
                                  </>
                                ) : (
                                  /* Show only the current price if no discount */
                                  <span className="currentPrice">
                                    £
                                    {parseFloat(
                                      selectedItem?.delivery_item_price
                                    ).toFixed(2)}
                                  </span>
                                )}
                              </p>
                            )}

                            {selectedItem?.pickup_item_price && (
                              <p className="platformPrice pickup">
                                <span className="platformName">
                                  Direct Order Price (<svg viewBox="0 0 24 24" className="directOrder-icon" height="15" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.869 3.284a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm2.109 9.162a8.519 8.519 0 0 1-2.418-1.266l-.288-.218a.25.25 0 0 0-.4.2v1.804a.993.993 0 0 0 .355.754 17.2 17.2 0 0 1 4.568 7.518 1.5 1.5 0 1 1-2.846.952 16.622 16.622 0 0 0-2.416-4.927.25.25 0 0 0-.433.081 17.332 17.332 0 0 1-2.56 5.309 1.5 1.5 0 1 1-2.344-1.873 12.745 12.745 0 0 0 2.213-4.7c.308-1.385.463-2.799.463-4.217v-1.357a.25.25 0 0 0-.38-.214 3.413 3.413 0 0 0-1.622 2.944 1.5 1.5 0 0 1-1.5 1.426 1.5 1.5 0 0 1-1.5-1.613 6.32 6.32 0 0 1 3.511-5.571 5.7 5.7 0 0 1 5.963.54l1.016.762a5.514 5.514 0 0 0 1.564.82l1.105.367a1.551 1.551 0 0 1 1.083 1.673 1.5 1.5 0 0 1-1.958 1.198l-1.176-.392Z"
                  ></path>
                </svg>):
                                </span>
                                {selectedItem?.pickup_item_discount_price ? (
                                  <>
                                    {/* Show discounted price with original price crossed out */}
                                    <span
                                      className="originalPrice"
                                      style={{
                                        textDecoration: "line-through",
                                        marginRight: "8px",
                                      }}
                                    >
                                      £
                                      {parseFloat(
                                        selectedItem?.pickup_item_price
                                      ).toFixed(2)}
                                    </span>
                                    <span className="discountedPrice">
                                      £
                                      {(
                                        parseFloat(selectedItem?.pickup_item_price) -
                                        (parseFloat(selectedItem?.pickup_item_price) *
                                          parseFloat(
                                            selectedItem?.pickup_item_discount_price
                                          )) /
                                          100
                                      ).toFixed(2)}
                                    </span>
                                  </>
                                ) : (
                                  /* Show only the current price if no discount */
                                  <span className="currentPrice">
                                    £
                                    {parseFloat(selectedItem?.pickup_item_price).toFixed(
                                      2
                                    )}
                                  </span>
                                )}
                              </p>
                            )}
                          </div>
                          {selectedItem?.food_item_desc_en !== null && (<div className="categoryDiscriptionModal">
                            {selectedItem?.food_item_desc_en ||
                              ""}
                          </div>)}
           
          </div>
          <hr className="customHr" />
          <div className="categoryModalSizeWrap">
            <h3>Select Size</h3>
            {sizes.map((size) => (
              <div key={size.name} className="size-option">
                <label className="sizeLabel">
                  <Radio
                    value={size}
                    checked={selectedSize.name === size.name}
                    onChange={() => setSelectedSize(size)}
                  />
                  <span>{size.name}</span>
                </label>
                <span className="price">&pound;{size.price}.00</span>
              </div>
            ))}

            <Title level={5} className="toppingHeading">
              Choose toppings
            </Title>
            <p className="toppingSubtext">Choose up to 50 additional items</p>
            {toppings.map((topping) => (
              <div key={topping.name} className="size-option">
                <label className="sizeLabel">
                  <Checkbox value={topping.name} />
                  <span className="sizeCheckBox"> {topping.name}</span>
                </label>
                <span className="price">&pound;{topping.price}.00</span>
              </div>
            ))}
          </div>
        </Modal>

   
    </div>
  );
};

export default FoodItems;
