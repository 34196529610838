// src/components/pages/Home.js
import React, { useState, useEffect  } from 'react';
import { Row, Col, Input, Radio, AutoComplete,Typography } from 'antd';
import axios from 'axios';
import FoodItems from '../features/Menu/FoodItems';
import '../../assets/css/Menu.css'; // Ensure this path is correct
import "../../assets/css/Font.css";
import aboutimg from "../../assets/images/video-cover-image-4.jpg";

const { Title } = Typography;

const Menu = () => {
  
  return (
    <>
    <div className='menuBanner' style={{display:'none'}}>
    <img src={aboutimg} alt='Menu Banner' />
    </div>
     <FoodItems />
    </>
  );
};

export default Menu;
