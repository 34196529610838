import React, { useContext, useState, useEffect } from "react";
import { Card, Carousel, Typography, Spin } from "antd";
import { BusinessContext } from "../../context/BusinessContext";
import { fetchPopularCate } from "../../utils/api";
import { Link } from "react-router-dom"; // Import Link for routing
import "../../assets/css/Menu.css";
import "./popularCate.css";

const { Title } = Typography;

const PopularCategories = () => {
  const [popularCatData, setPopularCatData] = useState([]);
  const { businessInfo, loading } = useContext(BusinessContext);

  useEffect(() => {
    const getPopularCate = async () => {
      try {
        const fetchedPopularCatData = await fetchPopularCate();
        if (fetchedPopularCatData) {
          setPopularCatData(fetchedPopularCatData.data || []);
        } else {
          console.error("Failed to fetch popular categories");
        }
      } catch (error) {
        console.error("Error fetching popular categories:", error);
      }
    };
    getPopularCate();
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="aboutSection">
      <div className="custom_container">
        <Title level={2} className="customHeading textCenter">
        {businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.popular_categories_en ||
            `Popular Categories`}
          
        </Title>
        <p className="popular-categories-description">
        {businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.popular_categories_desc_en ||
            `Explore a wide selection of authentic and flavorful dishes, from
          sizzling starters and smoky tandoori delights to aromatic curries and
          rich balti dishes. Whether you're in the mood for a traditional
          biryani or a fresh vegetable side dish, our menu offers something for
          everyone. Discover our most popular categories and find your perfect
          meal today!`}
         
        </p>
        <div className="popularcate-carousel">
          <Carousel
            dots={false}
            arrows={true}
            slidesToShow={4}
            slidesToScroll={1}
            autoplay
            autoplaySpeed={3000}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {popularCatData.map((category) => (
              <div style={{ padding: "0 10px" }} key={category.id}>
                <div className="popularcate-products-card">
                <Link to={`/menu#${category.id}`} className="category-link" title={category.menu_cat_name_en}>
                    <Card
                      hoverable
                      cover={
                        <div className="card-image-container">
                          <img
                            alt={category.menu_cat_name_en}
                            src={category.menu_cat_img}
                            className="category-image"
                          />
                          <div className="popularcate-overlay">
                            <div className="popularcate-count">
                              <span>{`${category.total_items || 0} items`}</span>
                            </div>
                            <div className="popularcate-content">
                              <div className="info">
                                <h3>{category.menu_cat_name_en}</h3>
                                <small>
                                  {`Starting from price £${parseFloat(
                                    category.starting_price || 0
                                  ).toFixed(2)}`}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </Link>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default PopularCategories;
