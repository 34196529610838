// src/components/HowItWorks.js
import React, { useState, useEffect, useContext } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import { Col, Typography, Row } from "antd";
import "./HowItWorks.css"; // Optional: for styling

const { Title } = Typography;

const HowItWorks = () => {
  const { businessInfo } = useContext(BusinessContext);
  const steps = [
    {
      title:
        businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_one_en ||
        `Something for everyone`,
      icon: (
        // Check if image URL is available
        businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_one_img ? (
          <img
            src={businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_one_img}
          />
        ) :(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
        >
          <circle
            cx="80"
            cy="80"
            r="80"
            fill="url(#paint0_linear_911_42934)"
            fill-opacity="0.4"
          />
          <path
            d="M49.3439 28.6116C51.3036 28.6116 53.004 29.9642 53.4446 31.8737L55.7107 41.6935C56.1428 43.5657 55.2586 45.3776 53.7409 46.2713L56.3249 76.5743C64.9897 85.0126 72.4375 96.6063 72.4375 108.733V150.555C72.4375 155.743 68.2316 159.949 63.0434 159.949L19.8951 159.949C14.7068 159.949 10.501 155.743 10.501 150.555V108.733C10.501 96.6063 17.9488 85.0126 26.6135 76.5743L29.1975 46.2713C27.6799 45.3777 26.7957 43.5657 27.2277 41.6935L29.4938 31.8737C29.9345 29.9642 31.6348 28.6116 33.5945 28.6116L49.3439 28.6116Z"
            fill="url(#paint1_linear_911_42934)"
          />
          <path
            d="M33.691 47.9788L48.7167 47.9788L51.4937 78.6285L55.7405 84.5318C60.0138 90.472 64.8555 97.6046 64.8555 104.922L64.8555 148.18C64.8555 150.989 62.5787 153.266 59.7702 153.266H23.1646C20.356 153.266 18.0793 150.989 18.0793 148.18L18.0792 104.922C18.0792 97.6046 22.9209 90.4719 27.1943 84.5318L31.4409 78.6286L33.691 47.9788Z"
            fill="#038A39"
          />
          <path
            d="M26.9724 131.527C26.9724 139.309 33.2813 145.618 41.0636 145.618H41.5143C49.2966 145.618 55.6055 139.309 55.6055 131.527L55.6055 103.968C55.6055 96.9396 49.7206 91.2422 42.4613 91.2422H40.1166C32.8573 91.2422 26.9724 96.9396 26.9724 103.968L26.9724 131.527Z"
            fill="url(#paint2_linear_911_42934)"
          />
          <path
            d="M49.8843 23C53.7581 23 56.8984 26.1403 56.8984 30.0141L56.8984 34.2935C56.0073 33.384 54.7652 32.8198 53.3913 32.8198C51.7852 32.8198 50.3591 33.591 49.4634 34.7833C48.5676 33.591 47.1415 32.8198 45.5354 32.8198C43.9293 32.8198 42.5034 33.5909 41.6076 34.7831C40.7118 33.5909 39.2858 32.8198 37.6798 32.8198C36.0736 32.8198 34.6476 33.591 33.7518 34.7833C32.856 33.591 31.43 32.8198 29.8238 32.8198C28.2992 32.8198 26.9369 33.5147 26.0363 34.6051L26.0363 30.0141C26.0363 26.1403 29.1767 23 33.0505 23L49.8843 23Z"
            fill="url(#paint3_linear_911_42934)"
          />
          <path
            d="M55.6562 68.71L54.8739 59.5356L41.4711 64.6906L28.0683 59.5357L27.286 68.71L41.4711 74.1658L55.6562 68.71Z"
            fill="url(#paint4_linear_911_42934)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.812 46.9666C32.3965 46.9666 31.249 48.1141 31.249 49.5296L31.249 77.9552C31.249 79.3707 32.3965 80.5182 33.812 80.5182C35.2275 80.5182 36.375 79.3707 36.375 77.9552L36.375 49.5296C36.375 48.1141 35.2275 46.9666 33.812 46.9666Z"
            fill="white"
          />
          <path
            d="M17.9062 122.614L17.9063 151.04"
            stroke="white"
            stroke-opacity="0.7"
            stroke-width="5.98663"
            stroke-linecap="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.812 37.4153C32.3965 37.4153 31.249 38.5628 31.249 39.9783L31.249 40.9229C31.249 42.3384 32.3965 43.4859 33.812 43.4859C35.2275 43.4859 36.375 42.3384 36.375 40.9229L36.375 39.9783C36.375 38.5628 35.2275 37.4153 33.812 37.4153Z"
            fill="white"
          />
          <path
            d="M17.9062 115.034L17.9063 115.979"
            stroke="white"
            stroke-opacity="0.7"
            stroke-width="5.98663"
            stroke-linecap="round"
          />
          <path
            d="M68.0039 131.999C68.0039 128.064 71.1939 124.874 75.1289 124.874H138.525C142.46 124.874 145.65 128.064 145.65 131.999V136.236C145.65 140.172 142.46 143.361 138.525 143.361H75.1289C71.1939 143.361 68.0039 140.172 68.0039 136.236V131.999Z"
            fill="url(#paint5_linear_911_42934)"
          />
          <path
            d="M73.5508 125.059V119.328H140.105V125.059L114.755 139.482C110.51 141.898 105.324 141.971 101.013 139.676L73.5508 125.059Z"
            fill="url(#paint6_linear_911_42934)"
          />
          <path
            d="M69.4071 117.294H144.244C149.02 124.879 138.571 128.035 133.58 123.394C133.131 134.513 119.531 131.937 117.116 123.394C116.102 135.868 97.5498 135.868 96.5356 123.394C94.1204 131.937 80.5205 134.513 80.0714 123.394C75.0805 128.035 64.6318 124.879 69.4071 117.294Z"
            fill="url(#paint7_linear_911_42934)"
          />
          <path
            d="M108.184 138.761L107.28 138.761"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="4.22222"
            stroke-linecap="round"
          />
          <path
            d="M120.383 132.886L116.768 134.693"
            stroke="white"
            stroke-opacity="0.5"
            stroke-width="4.22222"
            stroke-linecap="round"
          />
          <path
            d="M66.1562 112.912C66.1562 93.9931 81.4931 78.6562 100.412 78.6562H113.244C132.163 78.6562 147.5 93.9931 147.5 112.912C147.5 115.434 145.455 117.479 142.933 117.479H70.7237C68.2012 117.479 66.1562 115.434 66.1562 112.912Z"
            fill="url(#paint8_linear_911_42934)"
          />
          <path
            d="M68.0039 148.115C68.0039 145.492 70.1306 143.361 72.7539 143.361H140.9C143.524 143.361 145.65 145.492 145.65 148.115C145.65 154.674 140.334 160 133.775 160H79.8789C73.3205 160 68.0039 154.674 68.0039 148.115Z"
            fill="url(#paint9_linear_911_42934)"
          />
          <path
            d="M109.732 98.121C109.732 99.7255 108.431 101.026 106.827 101.026C105.223 101.026 103.922 99.7255 103.922 98.121C103.922 97.4312 104.459 95.9922 105.071 94.5756C105.753 92.9977 107.901 92.9977 108.583 94.5756C109.195 95.9922 109.732 97.4312 109.732 98.121Z"
            fill="url(#paint10_linear_911_42934)"
          />
          <path
            d="M123.242 88.415C124.631 89.2172 125.107 90.994 124.305 92.3835C123.503 93.773 121.726 94.2491 120.337 93.4468C119.739 93.102 118.762 91.9174 117.841 90.6789C116.815 89.2994 117.889 87.4391 119.597 87.6375C121.13 87.8156 122.644 88.0701 123.242 88.415Z"
            fill="url(#paint11_linear_911_42934)"
          />
          <path
            d="M89.0082 88.415C87.6187 89.2172 87.1426 90.994 87.9448 92.3835C88.7471 93.773 90.5238 94.2491 91.9133 93.4468C92.5107 93.102 93.4885 91.9174 94.4092 90.6789C95.4347 89.2994 94.3606 87.4391 92.6532 87.6375C91.1203 87.8156 89.6056 88.0701 89.0082 88.415Z"
            fill="url(#paint12_linear_911_42934)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_911_42934"
              x1="80"
              y1="0"
              x2="80"
              y2="160"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.21875" stop-color="#FFA0B7" />
              <stop offset="0.46875" stop-color="#FFA0B7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_911_42934"
              x1="48.0092"
              y1="35.339"
              x2="-2.01706"
              y2="131.586"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B2EF76" />
              <stop offset="1" stop-color="#038A39" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_911_42934"
              x1="41.5816"
              y1="90.548"
              x2="22.5413"
              y2="135.769"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9F9F9" />
              <stop offset="1" stop-color="#E1ED9C" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_911_42934"
              x1="45.5219"
              y1="19.5647"
              x2="40.9597"
              y2="30.4731"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9F9F9" />
              <stop offset="1" stop-color="#E1ED9C" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_911_42934"
              x1="49.2847"
              y1="59"
              x2="45.8022"
              y2="71.8592"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9F9F9" />
              <stop offset="1" stop-color="#E1ED9C" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_911_42934"
              x1="101.404"
              y1="154.577"
              x2="113.339"
              y2="136.058"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D56B2A" />
              <stop offset="1" stop-color="#723319" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_911_42934"
              x1="105.924"
              y1="115.713"
              x2="121.037"
              y2="133.849"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D56B2A" />
              <stop offset="1" stop-color="#FDB813" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_911_42934"
              x1="170.093"
              y1="103.059"
              x2="118.93"
              y2="59.8406"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#038A39" />
              <stop offset="1" stop-color="#7CC362" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_911_42934"
              x1="97.7899"
              y1="72.4968"
              x2="117.255"
              y2="104.938"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFCAAB" />
              <stop offset="1" stop-color="#D56B2A" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_911_42934"
              x1="140.372"
              y1="112.303"
              x2="154.362"
              y2="142.72"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFCAAB" />
              <stop offset="1" stop-color="#D56B2A" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_911_42934"
              x1="104.116"
              y1="91.3101"
              x2="107.661"
              y2="98.8395"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.291084" stop-color="#FFF5D8" />
              <stop offset="1" stop-color="#FFCAAB" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_911_42934"
              x1="115.499"
              y1="87.2995"
              x2="122.38"
              y2="93.2395"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.291084" stop-color="#FFF5D8" />
              <stop offset="1" stop-color="#FFCAAB" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_911_42934"
              x1="86.9441"
              y1="87.6949"
              x2="92.3477"
              y2="93.2395"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.291084" stop-color="#FFF5D8" />
              <stop offset="1" stop-color="#FFCAAB" />
            </linearGradient>
          </defs>
        </svg>
      )),
      description:
        businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_one_decs_en ||
        `We've got every meal covered and more: snacks, alcohol, dessert, paper towels...`,
    },
    {
      title:
        businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_two_en ||
        `Delivery or Collection`,
      icon: businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_two_img ? (
        <img
          src={businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_two_img}
        />
      ) :((
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="162"
          height="160"
          viewBox="0 0 162 160"
          fill="none"
        >
          <defs>
            <linearGradient
              id="paint0_linear_911_42963"
              x1="88.66"
              y1="14"
              x2="88.66"
              y2="53"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE7E7" />
              <stop offset="1" stop-color="#FFC4C4" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_911_42963"
              x1="57.4984"
              y1="0"
              x2="57.4984"
              y2="39"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D1F4FF" />
              <stop offset="1" stop-color="#A6E5FF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_911_42963"
              x1="81.6898"
              y1="66"
              x2="81.6898"
              y2="141.714"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE5A6" />
              <stop offset="1" stop-color="#FFCE6E" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_911_42963"
              x1="78.9754"
              y1="70.958"
              x2="78.9754"
              y2="99.2421"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#88D4F5" />
              <stop offset="1" stop-color="#63BEF2" />
            </linearGradient>
          </defs>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M63.2109 53H161.012C161.059 52.2413 161.083 51.4764 161.083 50.7059C161.083 30.4338 144.649 14 124.377 14C107.029 14 92.4915 26.0352 88.659 42.2118C86.8739 41.766 85.0061 41.5294 83.083 41.5294C74.5915 41.5294 67.1776 46.1429 63.2109 53Z"
            fill="url(#paint0_linear_911_42963)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.922813 39H113.809C111.442 30.4801 103.628 24.2271 94.3542 24.2271C92.6619 24.2271 91.0183 24.4353 89.4475 24.8275C86.0747 10.5915 73.2813 0 58.0141 0C45.0536 0 33.8759 7.6327 28.7307 18.6482C27.2092 18.3346 25.6334 18.1699 24.0191 18.1699C12.0025 18.1699 2.11805 27.2989 0.922813 39Z"
            fill="url(#paint1_linear_911_42963)"
          />
          <rect
            x="11.25"
            y="117.573"
            width="144.855"
            height="24.0938"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M104.883 141.714H59.3274C59.3274 130.006 49.1294 120.514 36.5495 120.514C23.9696 120.514 13.7716 130.006 13.7716 141.714H4.23281C3.18244 141.714 2.29372 140.938 2.15252 139.897L1.30239 133.631C1.01309 131.498 2.67104 129.6 4.82309 129.6L2.9811 120.457C2.65056 118.816 3.11968 116.285 4.00476 114.844L15.3986 96.2858C15.488 95.9806 25.9804 74.2468 25.9804 74.2468C28.2233 69.6922 34.4126 66 39.7846 66H82.1078C89.295 66 98.4596 70.4386 102.577 75.9134L122.78 102.343L139.089 103.876C152.546 104.702 160.8 115.254 157.537 127.403L156.947 129.6C159.883 129.6 161.751 132.74 160.351 135.32L157.477 140.616C157.11 141.293 156.402 141.714 155.632 141.714H150.439C150.439 130.006 140.241 120.514 127.661 120.514C115.081 120.514 104.883 130.006 104.883 141.714Z"
            fill="url(#paint2_linear_911_42963)"
          />
          <path
            d="M120.411 99.2421H59.5391V70.958H97.1116C99.3148 72.4159 101.221 74.1083 102.579 75.9134L120.411 99.2421Z"
            fill="url(#paint3_linear_911_42963)"
          />
        </svg>
      )),
      description:
        businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_two_desc_en ||
        `Sit back and relax, have us deliver to you or skip the line with collection.`,
    },
    {
      title:
        businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_three_en ||
        `Save with Curry Inn`,
      icon: businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_three_img ? (
        <img
          src={businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.step_three_img}
        />
      ) :((
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="160"
          height="161"
          viewBox="0 0 160 161"
          fill="none"
        >
          <path
            opacity="0.8"
            d="M50.6505 49.0716L45.153 49.0716L45.153 47.9226C45.153 47.9226 45.153 47.9226 45.1475 47.9226L45.1475 43.5813C45.1475 43.4729 45.0879 43.3808 45.0066 43.3157C44.9361 43.2669 44.8494 43.229 44.7518 43.229L40.6856 43.229C40.4687 43.229 40.2898 43.3862 40.2898 43.5813L40.2898 49.077L39.1404 49.077C39.1404 49.077 39.1404 49.077 39.1404 49.0824L34.7977 49.0824C34.6893 49.0824 34.5971 49.142 34.5321 49.2233C34.4833 49.2938 34.4453 49.3805 34.4453 49.4781L34.4453 53.5429C34.4453 53.7597 34.6025 53.9386 34.7977 53.9386L40.2898 53.9386L40.2898 55.0876C40.2898 55.0876 40.2898 55.0876 40.2952 55.0876L40.2952 59.4288C40.2952 59.5372 40.3549 59.6294 40.4362 59.6944C40.5067 59.7432 40.5934 59.7811 40.691 59.7811L44.7572 59.7811C44.974 59.7811 45.153 59.624 45.153 59.4288L45.153 53.9386L46.3023 53.9386C46.3023 53.9386 46.3023 53.9386 46.3023 53.9331L50.645 53.9331C50.7535 53.9331 50.8456 53.8735 50.9107 53.7922C50.9595 53.7218 50.9974 53.6351 50.9974 53.5375L50.9974 49.4726C50.9974 49.2504 50.8402 49.0716 50.6505 49.0716Z"
            fill="#FCCF42"
          />
          <path
            opacity="0.8"
            d="M122.205 25.8426L116.708 25.8426L116.708 24.6936C116.708 24.6936 116.708 24.6936 116.702 24.6936L116.702 20.3523C116.702 20.2439 116.643 20.1518 116.561 20.0867C116.491 20.0379 116.404 20 116.306 20L112.24 20C112.023 20 111.844 20.1572 111.844 20.3523L111.844 25.848L110.695 25.848C110.695 25.848 110.695 25.848 110.695 25.8534L106.352 25.8534C106.244 25.8534 106.152 25.913 106.087 25.9943C106.038 26.0648 106 26.1515 106 26.2491L106 30.3139C106 30.5307 106.157 30.7096 106.352 30.7096L111.844 30.7096L111.844 31.8586C111.844 31.8586 111.844 31.8586 111.85 31.8586L111.85 36.1998C111.85 36.3082 111.91 36.4004 111.991 36.4654C112.061 36.5142 112.148 36.5521 112.246 36.5521L116.312 36.5521C116.529 36.5521 116.708 36.395 116.708 36.1998L116.708 30.7096L117.857 30.7096C117.857 30.7096 117.857 30.7096 117.857 30.7041L122.2 30.7041C122.308 30.7041 122.4 30.6445 122.465 30.5632C122.514 30.4928 122.552 30.4061 122.552 30.3085L122.552 26.2436C122.552 26.0214 122.395 25.8426 122.205 25.8426Z"
            fill="#FCCF42"
          />
          <path
            opacity="0.8"
            d="M148.433 129.121L139.255 129.121L139.255 127.202C139.255 127.202 139.255 127.202 139.246 127.202L139.246 119.955C139.246 119.774 139.146 119.62 139.011 119.511C138.893 119.43 138.748 119.367 138.585 119.367L131.797 119.367C131.435 119.367 131.136 119.629 131.136 119.955L131.136 129.13L129.217 129.13C129.217 129.13 129.217 129.13 129.217 129.139L121.967 129.139C121.786 129.139 121.632 129.238 121.524 129.374C121.442 129.492 121.379 129.636 121.379 129.799L121.379 136.585C121.379 136.947 121.641 137.246 121.967 137.246L131.136 137.246L131.136 139.164C131.136 139.164 131.136 139.164 131.145 139.164L131.145 146.412C131.145 146.593 131.245 146.747 131.38 146.855C131.498 146.937 131.643 147 131.806 147L138.594 147C138.956 147 139.255 146.738 139.255 146.412L139.255 137.246L141.174 137.246C141.174 137.246 141.174 137.246 141.174 137.237L148.424 137.237C148.605 137.237 148.759 137.137 148.867 137.002C148.949 136.884 149.012 136.739 149.012 136.576L149.012 129.79C149.012 129.419 148.75 129.121 148.433 129.121Z"
            fill="#FCCF42"
          />
          <path
            opacity="0.4"
            d="M159.565 93.6416L152.708 93.6416L152.708 92.2084C152.708 92.2084 152.708 92.2084 152.701 92.2084L152.701 86.7934C152.701 86.6582 152.626 86.5433 152.525 86.4622C152.437 86.4013 152.329 86.354 152.207 86.354L147.135 86.354C146.865 86.354 146.642 86.5501 146.642 86.7934L146.642 93.6484L145.208 93.6484C145.208 93.6484 145.208 93.6484 145.208 93.6551L139.791 93.6551C139.656 93.6551 139.541 93.7295 139.46 93.8309C139.399 93.9188 139.352 94.027 139.352 94.1486L139.352 99.2189C139.352 99.4893 139.548 99.7124 139.791 99.7124L146.642 99.7124L146.642 101.146C146.642 101.146 146.642 101.146 146.648 101.146L146.648 106.561C146.648 106.696 146.723 106.811 146.824 106.892C146.912 106.953 147.02 107 147.142 107L152.214 107C152.484 107 152.708 106.804 152.708 106.561L152.708 99.7124L154.141 99.7124C154.141 99.7124 154.141 99.7124 154.141 99.7056L159.558 99.7056C159.693 99.7056 159.808 99.6313 159.889 99.5299C159.95 99.442 159.998 99.3338 159.998 99.2121L159.998 94.1419C159.998 93.8647 159.801 93.6416 159.565 93.6416Z"
            fill="#FCCF42"
          />
          <path
            opacity="0.4"
            d="M149.824 7.14715L143.099 7.14715L143.099 5.74159C143.099 5.74159 143.099 5.74159 143.092 5.74159L143.092 0.430951C143.092 0.29835 143.019 0.18564 142.919 0.10608C142.833 0.0464102 142.727 2.82591e-07 142.608 2.7994e-07L137.634 1.69496e-07C137.368 1.63605e-07 137.149 0.19227 137.149 0.430951L137.149 7.15378L135.743 7.15378C135.743 7.15378 135.743 7.15378 135.743 7.16041L130.431 7.16041C130.298 7.16041 130.186 7.23334 130.106 7.33279C130.046 7.41898 130 7.52506 130 7.6444L130 12.6169C130 12.8821 130.192 13.1009 130.431 13.1009L137.149 13.1009L137.149 14.5065C137.149 14.5065 137.149 14.5065 137.156 14.5065L137.156 19.8171C137.156 19.9497 137.229 20.0624 137.329 20.142C137.415 20.2016 137.521 20.248 137.64 20.248L142.614 20.248C142.88 20.248 143.099 20.0558 143.099 19.8171L143.099 13.1009L144.505 13.1009C144.505 13.1009 144.505 13.1009 144.505 13.0943L149.817 13.0943C149.95 13.0943 150.062 13.0213 150.142 12.9219C150.202 12.8357 150.248 12.7296 150.248 12.6103L150.248 7.63777C150.248 7.36594 150.056 7.14715 149.824 7.14715Z"
            fill="#FCCF42"
          />
          <path
            opacity="0.5"
            d="M43.507 11.6486L32.5468 11.6486L32.5468 9.35791C32.5468 9.35791 32.5468 9.35791 32.5359 9.35791L32.5359 0.702836C32.5359 0.486729 32.4171 0.303038 32.2549 0.173374C32.1144 0.076126 31.9415 0.000488742 31.7469 0.000488737L23.6402 0.000488557C23.2079 0.000488548 22.8512 0.313843 22.8512 0.702835L22.8512 11.6595L20.5597 11.6595C20.5597 11.6595 20.5597 11.6595 20.5597 11.6703L11.9018 11.6703C11.6856 11.6703 11.5019 11.7891 11.3722 11.9512C11.2749 12.0917 11.1992 12.2646 11.1992 12.459L11.1992 20.5631C11.1992 20.9953 11.5127 21.3518 11.9018 21.3518L22.8512 21.3518L22.8512 23.6426C22.8512 23.6426 22.8512 23.6426 22.862 23.6426L22.862 32.2977C22.862 32.5138 22.9809 32.6975 23.143 32.8271C23.2836 32.9244 23.4565 33 23.6511 33L31.7577 33C32.1901 33 32.5468 32.6866 32.5468 32.2977L32.5468 21.3518L34.8382 21.3518C34.8382 21.3518 34.8382 21.3518 34.8382 21.341L43.4962 21.341C43.7123 21.341 43.8961 21.2222 44.0258 21.0601C44.1231 20.9196 44.1987 20.7467 44.1987 20.5523L44.1987 12.4482C44.1987 12.0052 43.8853 11.6486 43.507 11.6486Z"
            fill="#FCCF42"
          />
          <path
            opacity="0.9"
            d="M21.4272 55.2685L14.1582 55.2685L14.1582 53.7493C14.1582 53.7493 14.1582 53.7493 14.151 53.7493L14.151 48.009C14.151 47.8657 14.0721 47.7439 13.9646 47.6579C13.8714 47.5934 13.7567 47.5432 13.6277 47.5432L8.25116 47.5432C7.96442 47.5432 7.72785 47.751 7.72785 48.009L7.72785 55.2757L6.20809 55.2757C6.20809 55.2757 6.20809 55.2757 6.20809 55.2829L0.465966 55.2829C0.322592 55.2829 0.200726 55.3617 0.114702 55.4692C0.0501836 55.5624 1.80601e-07 55.677 1.83465e-07 55.806L3.02805e-07 61.1808C3.0917e-07 61.4674 0.207893 61.7039 0.465966 61.7039L7.72785 61.7039L7.72785 63.2232C7.72785 63.2232 7.72785 63.2232 7.73502 63.2232L7.73502 68.9634C7.73502 69.1067 7.81387 69.2286 7.9214 69.3146C8.0146 69.3791 8.1293 69.4292 8.25833 69.4292L13.6349 69.4292C13.9216 69.4292 14.1582 69.2214 14.1582 68.9634L14.1582 61.7039L15.6779 61.7039C15.6779 61.7039 15.6779 61.7039 15.6779 61.6967L21.42 61.6967C21.5634 61.6967 21.6853 61.6179 21.7713 61.5104C21.8358 61.4173 21.886 61.3026 21.886 61.1736L21.886 55.7988C21.886 55.505 21.6781 55.2685 21.4272 55.2685Z"
            fill="#FCCF42"
          />
          <path
            d="M80.7868 37.2867C83.0223 33.4149 87.9732 32.0883 91.8451 34.3237L135.311 59.4188C139.183 61.6542 140.509 66.6051 138.274 70.477L88.8934 156.007C86.658 159.879 81.7071 161.205 77.8352 158.97L34.3693 133.875C30.4974 131.639 29.1708 126.688 31.4062 122.816L80.7868 37.2867Z"
            fill="#32270B"
          />
          <path
            d="M75.1775 34.0489C77.4129 30.1771 82.3638 28.8505 86.2357 31.0859L129.702 56.181C133.574 58.4164 134.9 63.3673 132.665 67.2392L83.2841 152.769C81.0486 156.641 76.0977 157.967 72.2258 155.732L28.7599 130.637C24.888 128.402 23.5614 123.451 25.7968 119.579L75.1775 34.0489Z"
            fill="url(#paint0_linear_911_43014)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M60.8712 142.996C61.3826 142.086 62.535 141.763 63.4452 142.274L71.6241 146.869C72.5343 147.381 72.8575 148.533 72.3462 149.443C71.8348 150.353 70.6824 150.677 69.7722 150.165L61.5933 145.57C60.6832 145.059 60.3599 143.906 60.8712 142.996Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M74.219 150.585C74.7498 149.686 75.9089 149.387 76.8078 149.918L77.8556 150.537C78.7545 151.068 79.053 152.227 78.5222 153.126C77.9913 154.025 76.8323 154.323 75.9333 153.792L74.8855 153.174C73.9866 152.643 73.6881 151.484 74.219 150.585Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M81.8702 32.7004C82.4103 31.8071 83.5725 31.5207 84.4658 32.0609L85.3831 32.6155C86.2765 33.1557 86.5628 34.3178 86.0227 35.2112C85.4825 36.1046 84.3204 36.3909 83.427 35.8507L82.5097 35.2961C81.6163 34.7559 81.33 33.5938 81.8702 32.7004Z"
            fill="white"
            fill-opacity="0.3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M88.3458 36.4111C88.8704 35.5085 90.0274 35.2022 90.93 35.7269L111.351 47.5981C112.254 48.1228 112.56 49.2798 112.036 50.1824C111.511 51.085 110.354 51.3913 109.451 50.8666L89.03 38.9954C88.1274 38.4707 87.8211 37.3137 88.3458 36.4111Z"
            fill="white"
            fill-opacity="0.3"
          />
          <rect
            x="79.9766"
            y="38.688"
            width="53.4282"
            height="90.6661"
            transform="rotate(30 79.9766 38.688)"
            fill="url(#paint1_linear_911_43014)"
          />
          <path
            d="M89.2863 110.629L59.8451 93.7852C59.6156 93.6539 59.5407 93.3532 59.6784 93.1126L72.5307 70.648C72.6684 70.4073 72.9656 70.3195 73.1951 70.4508L102.648 87.3012C102.95 87.4741 102.965 87.9137 102.676 88.1292L91.5245 96.4927C91.4088 96.5804 91.3324 96.7138 91.3201 96.8568L90.0178 110.22C89.9813 110.588 89.5915 110.804 89.2863 110.629Z"
            fill="#FFC61B"
          />
          <path
            d="M88.2508 91.6614L83.1609 88.7493L83.768 87.6882L83.765 87.6865L86.058 83.6721C86.1144 83.5735 86.1102 83.4595 86.0636 83.3521C86.0262 83.2691 85.963 83.1906 85.873 83.1391L82.1084 80.9853C81.9049 80.8689 81.6563 80.919 81.5534 81.0988L78.6505 86.1728L77.5867 85.5641L77.585 85.567L73.5602 83.2682C73.4612 83.2116 73.3473 83.2157 73.2398 83.2619C73.1566 83.299 73.0782 83.3619 73.0268 83.4518L70.8787 87.2065C70.7625 87.4094 70.8134 87.6579 70.9937 87.7611L76.0807 90.6714L75.4736 91.7326L75.4766 91.7343L73.1835 95.7486C73.1271 95.8472 73.1314 95.9613 73.1779 96.0687C73.2154 96.1517 73.2785 96.2302 73.3686 96.2817L77.1332 98.4355C77.3367 98.5519 77.5853 98.5017 77.6882 98.3219L80.5927 93.2451L81.6565 93.8537L81.6582 93.8508L85.683 96.1497C85.782 96.2063 85.896 96.2022 86.0034 96.1559C86.0866 96.1188 86.165 96.056 86.2165 95.9661L88.3629 92.2143C88.4818 92.0129 88.4282 91.7629 88.2508 91.6614Z"
            fill="#32270B"
          />
          <defs>
            <linearGradient
              id="paint0_linear_911_43014"
              x1="107.969"
              y1="43.6335"
              x2="95.5129"
              y2="145.026"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B6821E" />
              <stop offset="1" stop-color="#32270B" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_911_43014"
              x1="106.691"
              y1="38.688"
              x2="145.521"
              y2="107.415"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="0.854167" stop-color="#FFF5D8" />
            </linearGradient>
          </defs>
        </svg>
      )),
      description:
        businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]
          ?.step_three_desc_en ||
        `Join Curry Inn and get unlimited £0 delivery fees, exclusive offers, and more.`,
    },
  ];

  return (
    <div className="howItWorkSection">
      <div className="custom_container">
        <Title level={2} className="customHeading">
          {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.how_it_works_en ||
            `Why you'll love Curry Inn`}
        </Title>
        <Row gutter={20} className="steps">
          {steps.map((step, index) => (
            <Col
              xs={24} // Full width on extra small screens
              sm={12} // Two columns on small screens
              md={8} // Three columns on medium screens
              xl={8} // Three columns on large screens
              xxl={8} // Three columns on extra-large screens
              className="step"
              key={index}
            >
              {step.icon}
              <Title level={4}>{step.title}</Title>
              <p>{step.description}</p>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default HowItWorks;
