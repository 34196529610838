// src/components/layouts/Header.js
import React, { useContext, useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Badge,
  Typography,
  Modal,
  Input,
  Space,
  Checkbox,
  Radio,
  Form,
  Button,
  Drawer,
  List,
  Row,
  Col,
  Spin,
  Empty,
  message,
  Tooltip,
} from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CartContext from "../../context/CartContext";
import { BusinessContext } from "../../context/BusinessContext";
import "../../assets/css/Header.css";
import emptycart from "../../assets/images/empty_cart.png";
import { ArrowRightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { userSignUp, userSignIn, handleOtpVerification } from "../../utils/api";
const { Header } = Layout;
const { TextArea } = Input;
const { Title, Text } = Typography;

const MyHeader = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSignUpOpen, setIsModalSignUpOpen] = useState(false);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
  const {cart, updateItem, updateOrderType } = useContext(CartContext);
  const orderType = cart?.Carts.order_type;
  const comparisionDiscount = cart?.Carts?.ComparisonRate ?? 0;
  const [Cartloading, setCartLoading] = useState(false);
  const [userLogged, setLoggedUser] = useState(null);
  const [isOtpVerificationVisible, setIsOtpVerificationVisible] = useState(false);
  const [userEmail, setuserEmail] = useState("");
    // Modal functions for Sign-in/Sign-up
    const showModal = () => setIsModalOpen(true);
    const handleOk = () => setIsModalOpen(false);
    const handleCancel = () => setIsModalOpen(false);
    const showSignUpModal = () => setIsModalSignUpOpen(true);
    const handleSignUpOk = () => setIsModalSignUpOpen(false);
    const handleSignUpCancel = () => setIsModalSignUpOpen(false);
  
    const handleForgotPassword = () => {
      setForgotPasswordVisible(true);
    };
  
    const handleForgotPasswordSubmit = (values) => {
      console.log("Forgot Password Values:", values);
      setForgotPasswordVisible(false);
    };
   
    const handleUserSignUpSubmit = async (values) => {
      try {
        const formData = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.user_email,
          password: values.user_password,
          mobile_number: values.user_phone,
          user_type: 2,
        };
        const response = await userSignUp(formData);
        if (response && response.status === "success" && response.status_code === 200) {
          setuserEmail(values.user_email);
          setIsModalSignUpOpen(false);
          setTimeout(() => {
            setIsOtpVerificationVisible(true);
          }, 300);
          message.success({
            content:
              response.message ||
              "We've sent an OTP to your email or mobile number. Please enter the code to complete registration.",
            duration: 5,
          });
        } else if (response && response.status === "error" && response.status_code === 422) {
          // Handle validation errors
          const { errors } = response;
          Object.keys(errors).forEach((field) => {
            message.error({
              content: errors[field][0], // Show the first error for each field
              duration: 5,
            });
          });
        } else {
          message.error({
            content: response.message || "Failed to create the account. Please try again.",
            duration: 5,
          });
        }
      } catch (error) {
        console.error("Sign up error:", error);
        message.error({
          content: "An unexpected error occurred. Please try again later.",
          duration: 5,
        });
      }
    };
    

  // Handle OTP verification submission
  const handleOTPSubmit = async (otp) => {
    try {
      // Call your OTP verification API
      const formData = { otp, userEmail };
      const response = await handleOtpVerification(formData);
      if (response && response.status === "success" && response.status_code === 200) {
        message.success({
          content: response.message || "OTP verified successfully!",
          duration: 5, // Display for 5 seconds
        });
        setIsOtpVerificationVisible(false); // Close OTP modal
      } else {
        message.error({
          content: response.message || "Invalid OTP. Please try again.",
          duration: 5, // Display for 5 seconds
        });
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      message.error("An unexpected error occurred. Please try again later.");
    }
  };


  const handleUserSignInSubmit = async (values) => {
    try {
      const formData = {
        email: values.user_email,
        password: values.user_password
      };
      const response = await userSignIn(formData);
      if (response && response.status === "success" && response.status_code === 200) {
        message.success({
          content: response.message || "Login successful. Welcome back!",
          duration: 5, // Display for 5 seconds
        });
        console.log(response);
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("userId", response.data.id);
        localStorage.setItem("userLoggedInfo", JSON.stringify(response.data));
        window.location.href = "/"; // Redirect after login
      } else {
        message.error({
          content: response.message || "Failed to log in the account. Please try again.",
          duration: 5,
        });
      }
    } catch (error) {
      console.error("Sign In error:", error);
      message.error({
        content: "An unexpected error occurred. Please try again later.",
        duration: 5,
      });
    }
  };

  const CustomEmpty = () => (
    <Empty
      image={emptycart} // Replace with your custom icon URL
      imageStyle={{
        height: 80,
      }}
      description={
        <div>
          <h2>Your Cart is Empty!</h2>
          <p>Must add items to the cart before you proceed to checkout.</p>
        </div>
      }
    />
  );

  const handleUpdateItem = async (itemId, newQuantity) => {
    setCartLoading(true); // Start loading
    try {
      await updateItem(itemId, newQuantity); // Perform the update operation
    } catch (error) {
      console.error("Failed to update item:", error);
    } finally {
      setCartLoading(false); // Stop loading
    }
  };

  const handleUpdateOrderType = async (order_type) => {
    setCartLoading(true); // Start loading
    try {
      await updateOrderType(order_type); // Perform the update operation
    } catch (error) {
      console.error("Failed to update item:", error);
    } finally {
      setCartLoading(false); // Stop loading
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const handleCheckout = () => {
    setIsCartDrawerOpen(false);
    navigate("/order"); // Navigate to the checkout page
  };

  const handleLogout = () => {
  const isConfirmed = window.confirm("Are you sure you want to log out?");
    if (isConfirmed) {
      // Clear user data
      localStorage.removeItem("userLoggedInfo");
      localStorage.removeItem("userId");
      localStorage.removeItem("authToken");
      window.location.href = "/"; // Redirect to home page
    } else {
      // User clicked cancel, do nothing
      return;
    }
  };

  // Cart Drawer functions
  const showCartDrawer = () => setIsCartDrawerOpen(true);
  const closeCartDrawer = () => setIsCartDrawerOpen(false);
  // Sample cart total calculation
  useEffect(() => {
    const savedUser = localStorage.getItem("userLoggedInfo");
    if (savedUser) {
      setLoggedUser(JSON.parse(savedUser));
    }
  }, []);

  const { businessInfo, loading } = useContext(BusinessContext);
  if (loading) {
    return "";
  }
  return (

    <Layout className="custom_layout">

      <Header className="custom-header">

        <Link to="/" className="logo">
          <img
            src={
              businessInfo.businessInfo[0]?.restaurant_logo ||
              "https://a.storyblok.com/f/177063/126x125/4567c283c7/tfk-logo.svg"
            }
            alt={businessInfo.businessInfo[0]?.restaurant_name_en || "PDQ2"}
          />
        </Link>
        <Menu
          className={`menu ${menuVisible ? "active" : ""}`}
          theme="light"
          mode="horizontal"
          selectedKeys={[location.pathname]}
          selectable={false}
        >
          <Menu.Item
            key="menu"
            className={location.pathname === "/menu" ? "active_menu" : ""}
          >
            <Link to="/menu">Online Order</Link>
          </Menu.Item>
          <Menu.Item
            key="our-offer"
            className={location.pathname === "/our-offer" ? "active_menu" : ""}
          >
            <Link to="/our-offer">Our Offers</Link>
          </Menu.Item>
          
          {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.reservation_enable === 0 && (<Menu.Item
            key="reservation"
            className={
              location.pathname === "/reservation" ? "active_menu" : ""
            }
          >
            <Link to="/reservation">Book a Table</Link>
          </Menu.Item>)}
          <Menu.Item
            key="contact-us"
            className={location.pathname === "/contact-us" ? "active_menu" : ""}
          >
            <Link to="/contact-us">Contact Us</Link>
          </Menu.Item>

          {!userLogged ? (
        <>
         <Menu.Item key="signin" className="btn_sign" onClick={showModal}>
            <Link>Sign In</Link>
          </Menu.Item>
          <Menu.Item
            key="signup"
            className="btn_join"
            onClick={showSignUpModal}
          >
            <Link>Join Now</Link>
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="userLogged" >
            Hi, {userLogged.first_name || "User"}!
          </Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout}>
            <span>Logout</span>
          </Menu.Item>
        </>
      )}
          <Menu.Item key="cart" className="cart-item" onClick={showCartDrawer}>
            <Badge count={cart?.data?.length ?? 0} showZero>
              <svg
                className="cart-icon"
                width="24"
                height="29"
                viewBox="0 0 24 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.889 6.66669H19.5031C19.0617 2.9179 15.8654 0 12 0C8.13464 0 4.93841 2.9179 4.49702 6.66669H1.11111C0.497446 6.66669 0 7.16414 0 7.77781V27.3334C0 27.9471 0.497446 28.4445 1.11111 28.4445H22.889C23.5026 28.4445 24.0001 27.9471 24.0001 27.3334V7.77781C24.0001 7.16414 23.5026 6.66669 22.889 6.66669ZM11.9997 2.22239C14.6377 2.22239 16.8341 4.14768 17.2586 6.66685H6.74088C7.16532 4.14768 9.36172 2.22239 11.9997 2.22239ZM2.22215 26.2225H21.7778V8.88908H19.5555V12.2224C19.5555 12.8361 19.0581 13.3335 18.4444 13.3335C17.8308 13.3335 17.3333 12.8361 17.3333 12.2224V8.88908H6.66661V12.2224C6.66661 12.8361 6.16916 13.3335 5.5555 13.3335C4.94183 13.3335 4.44438 12.8361 4.44438 12.2224V8.88908H2.22215V26.2225Z"
                  fill="#3B3D2F"
                ></path>
              </svg>
            </Badge>

          </Menu.Item>
        </Menu>
      </Header>

      {/* Sign In Modal */}
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="authModal"
        footer={null}
        maskClosable={false}
      >
        <div className="authBg"></div>
        <div className="authModalBody">
          <Title level={3} className="modalHeading">
            Welcome Back!
          </Title>
          <p className="modalSubHeading">
            Please log in with your email address and password to continue.
          </p>
          <div className="">
            <Form layout="vertical" onFinish={handleUserSignInSubmit} initialValues={{ user_email: '', user_password: '' }}>
              {/* Email Address */}
              <Form.Item
                label="Email Address"
                name="user_email"
                rules={[
                  { required: true, message: "Please enter your email address" },
                  { type: "email", message: "Please enter a valid email address" },
                ]}
              >
                <Input
                  placeholder="Enter your email address"
                  className="custom_input_field"
                />
              </Form.Item>

              {/* Password */}
              <Form.Item
                label="Password"
                name="user_password"
                rules={[
                  { required: true, message: "Please enter your password" },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  {
                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must include at least one uppercase, one lowercase, one number, and one special character",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  className="custom_input_field"
                />
              </Form.Item>

              {/* Submit Button */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w100 customModalBtn"
                >
                  Login
                </Button>
              </Form.Item>

              {/* Forgot Password Link */}
              <div className="forgot-password">
                <a onClick={handleForgotPassword} className="link">
                  Forgot Password?
                </a>
              </div>

              {/* Social Login */}
              <div className="social-login">
                <Button className="social-btn twitter-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M22.46 6c-.77.35-1.61.58-2.48.69a4.36 4.36 0 0 0 1.92-2.41 8.64 8.64 0 0 1-2.77 1.06 4.32 4.32 0 0 0-7.46 3.93 12.27 12.27 0 0 1-8.91-4.52 4.32 4.32 0 0 0 1.34 5.77 4.28 4.28 0 0 1-1.96-.54v.05a4.32 4.32 0 0 0 3.47 4.24 4.33 4.33 0 0 1-1.95.07 4.33 4.33 0 0 0 4.03 3 8.66 8.66 0 0 1-5.36 1.85A8.83 8.83 0 0 1 2 20.13a12.2 12.2 0 0 0 6.63 1.95c7.95 0 12.29-6.58 12.29-12.29 0-.19-.01-.38-.02-.57a8.8 8.8 0 0 0 2.16-2.22"
                      fill="currentColor"
                    />
                  </svg>
                  Login with Twitter
                </Button>
                <Button className="social-btn google-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M12 2.04c2.1 0 4.03.78 5.52 2.07l-2.27 2.26C14.14 5.57 13.1 5.27 12 5.27c-2.14 0-3.95 1.44-4.6 3.39H5.01v2.79H7.4c.15.62.38 1.2.68 1.73v2.43h2.79v-2.03c.48-.18.94-.43 1.37-.72h-.04v2.75h2.78v-2.43c.3-.52.52-1.11.66-1.73H17v-2.79h-2.29c-.17-.63-.41-1.2-.73-1.75h-.01L12 4.09c.02-.02.02-.04.02-.05 0-.04-.02-.07-.02-.1 0-.08.03-.15.04-.22"
                      fill="currentColor"
                    />
                  </svg>
                  Login with Google
                </Button>
                <Button className="social-btn facebook-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M13.06 24V12.25H16.6l.52-4.02h-4.06V5.69c0-1.16.32-1.95 2-1.95h2.17V.24c-.38-.04-1.7-.17-3.24-.17-3.2 0-5.4 1.95-5.4 5.54v3.12H5.11V12.3h3.5V24"
                      fill="currentColor"
                    />
                  </svg>
                  Login with Facebook
                </Button>
              </div>
            </Form>

            {/* Forgot Password Modal */}
            <Modal
              title="Reset Password"
              visible={forgotPasswordVisible}
              onCancel={() => setForgotPasswordVisible(false)}
              footer={null}
              maskClosable={false}
            >
              <Form layout="vertical" onFinish={handleForgotPasswordSubmit}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your email address" },
                    { type: "email", message: "Please enter a valid email address" },
                  ]}
                >
                  <Input
                    placeholder="Enter your email address"
                    className="custom_input_field"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>

          </div>

          <div className="authFooter">
            By continuing, you agree to our{" "}
            <span>
            <a href="/terms-conditions" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>.
            </span>
          </div>
        </div>
      </Modal>

      {/* Sign Up Modal */}
      {isModalSignUpOpen && (<Modal
        title=""
        open={isModalSignUpOpen}
        onOk={handleSignUpOk}
        onCancel={handleSignUpCancel}
        className="authModal"
        footer={null}
        maskClosable={false}
      >
        <div className="authBg"></div>
        <div className="authModalBody">
          <Title level={3} className="modalHeading">
            Join Us Today!
          </Title>
          <p className="modalSubHeading">
            Create an account with your email address and a secure password to get started.
          </p>
          <Form layout="vertical" onFinish={handleUserSignUpSubmit} initialValues={{ first_name: '', last_name: '', user_email: '', user_password: '', user_phone: '', }}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: "Please enter your first name" }]}
            >
              <Input
                placeholder="Enter your first name"
                className="custom_input_field"
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true, message: "Please enter your last name" }]}
            >
              <Input
                placeholder="Enter your last name"
                className="custom_input_field"
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="user_email"
              rules={[
                { required: true, message: "Please enter your email" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Please enter a valid email format",
                },
              ]}
            >
              <Input
                placeholder="Enter your email address"
                className="custom_input_field"
              />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="user_phone"
              rules={[
                { required: true, message: "Please enter your phone number" },
                {
                  pattern: /^[0-9]{12}$/,
                  message: "Phone number must be 12 digits",
                },
              ]}
            >
              <Input
                placeholder="Enter your phone number"
                maxLength={12}
                className="custom_input_field"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="user_password"
              rules={[
                { required: true, message: "Please enter your password" },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$!%*?&]{8,}$/,
                  message: "Password must include at least one uppercase, one lowercase, one number, and one special character",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Enter your password"
                className="custom_input_field"
              />
            </Form.Item>


            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={["user_password"]}
              rules={[
                { required: true, message: "Please confirm your password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("user_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match"));
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Confirm your password"
                className="custom_input_field"
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Password Guidelines&nbsp;
                  <Tooltip title="Password must have at least 8 characters, including uppercase, lowercase, a number, and a special character like @#$">
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="password_guidelines"
              style={{ marginBottom: "20px" }}
            >
              <span style={{ fontSize: "12px", color: "#888" }}>
                <ul>
                  <li>At least 8 characters long</li>
                  <li>Contains uppercase & lowercase letters</li>
                  <li>Includes at least one number</li>
                  <li>Has one special character (e.g., @#$)</li>
                </ul>
              </span>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w100 customModalBtn"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          <div className="authFooter">
            By continuing, you agree to our{" "}
            <span>
              <a href="/terms-conditions" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>.
            </span>
          </div>

        </div>
      </Modal>)}

      {/* OTP Verification Modal */}
      {isOtpVerificationVisible  && (<Modal
        title="OTP Verification"
        visible={isOtpVerificationVisible}
        onCancel={() => setIsOtpVerificationVisible(false)}
        footer={null}
        maskClosable={false}
      >
        <div className="authModalBody">
          <Title level={3} className="modalHeading">
            OTP Verification
          </Title>
          <p className="modalSubHeading">
            Please enter the OTP sent to your email or mobile number to continue.
          </p>
          <Form layout="vertical" onFinish={(values) => handleOTPSubmit(values.otp)}>
            <Form.Item
              label="OTP"
              name="otp"
              rules={[{ required: true, message: "Please enter the OTP" }]}
            >
              <Input placeholder="Enter OTP" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="w100 customModalBtn">
                Verify OTP
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>)}

      {/* Cart Drawer */}
      <Drawer
        title="Cart Summary"
        placement="right"
        onClose={closeCartDrawer}
        open={isCartDrawerOpen}
        width={450}
      >
        <div className="cartListItem">
          <List
            locale={{
              emptyText: <CustomEmpty />,
            }}
            dataSource={cart.data || []}
            renderItem={(item) => (

              <List.Item key={item.id}>
                <div className="headercartItemContainer">
                  {/* Main Item Details */}
                  <div className="headercartItemDetails">
                    <div className="headercartItemName">
                      {item.quantity} x {item.food_items.food_item_name_en}
                    </div>
                    <div className="headercartItemSize">
                      {/*  <span>+ Nan Bread</span> <span>(1 X £8.95)</span> */}
                    </div>
                    <div className="headercartItemExtras">
                      {/*  <span>+ Spicy Sauce</span> <span>(1 X £0.95)</span>, 
                    <span>+ Lemon Juice</span> <span>(1 X £0.95)</span> */}
                    </div>
                  </div>

                  {/* Quantity and Price Controls */}
                  <div className="headercartInputQtyWrap">
                    <Row align="middle" justify="space-between">
                      {/* Quantity Controls */}
                      <div className="headercartQuantityControls">
                        <Button type="default" className="headercartMinusBtn" icon={Cartloading ? (<Spin size="small" />) : ("")} onClick={() => handleUpdateItem(item.id, item.quantity - 1)}
                          disabled={Cartloading} > - </Button>

                        <div className="headercartInputQty">{item.quantity}</div>
                        <Button type="default" className="headercartPlusBtn" icon={Cartloading ? (<Spin size="small" />) : ("")} onClick={() => handleUpdateItem(item.id, item.quantity + 1)}
                          disabled={Cartloading} > + </Button>

                      </div>
                      {/* Price Details */}
                      <div className="headercartItemPricedetails">&pound;{(item.quantity * item.delivery_item_price).toFixed(2)}</div>
                    </Row>
                  </div>
                </div>
              </List.Item>


            )}
          />
        </div>
        {cart.data.length > 0 && (
          <div className="headerCartOrderType">
            <Title level={4} className="billHead">
              Choose Order Type
            </Title>

            <Radio.Group
              value={String(orderType)}
              onChange={(e) => handleUpdateOrderType(e.target.value)}
              buttonStyle="solid"
              className="customRadioGroup"
            >
              {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.pickup_type_enable === 0 && (
                <Radio.Button value="1" className="radioButton">
                  <div className="radioContent">
                    <svg viewBox="0 0 24 24" height="40" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.869 3.284a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm2.109 9.162a8.519 8.519 0 0 1-2.418-1.266l-.288-.218a.25.25 0 0 0-.4.2v1.804a.993.993 0 0 0 .355.754 17.2 17.2 0 0 1 4.568 7.518 1.5 1.5 0 1 1-2.846.952 16.622 16.622 0 0 0-2.416-4.927.25.25 0 0 0-.433.081 17.332 17.332 0 0 1-2.56 5.309 1.5 1.5 0 1 1-2.344-1.873 12.745 12.745 0 0 0 2.213-4.7c.308-1.385.463-2.799.463-4.217v-1.357a.25.25 0 0 0-.38-.214 3.413 3.413 0 0 0-1.622 2.944 1.5 1.5 0 0 1-1.5 1.426 1.5 1.5 0 0 1-1.5-1.613 6.32 6.32 0 0 1 3.511-5.571 5.7 5.7 0 0 1 5.963.54l1.016.762a5.514 5.514 0 0 0 1.564.82l1.105.367a1.551 1.551 0 0 1 1.083 1.673 1.5 1.5 0 0 1-1.958 1.198l-1.176-.392Z"
                      ></path>
                    </svg>
                    <span>{businessInfo?.businessInfo?.[0]?.homelng?.[0]?.collection_en ?? "Collection"}</span>
                    <span>{businessInfo?.businessInfo?.[0]?.portal_info?.[0]
                      ?.pickup_estimate_time}min Approx</span>
                  </div>
                </Radio.Button>
              )}
              {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.delivery_type_enable === 0 && (
                <Radio.Button value="0" className="radioButton">
                  <div className="radioContent">
                    <svg viewBox="0 0 24 24" height="40" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.401 9.602c.281.281.666.439 1.062.439h.039a1.48 1.48 0 0 0 1.075-.496l3.157-3.507a1.505 1.505 0 0 0-.055-2.071L8.098 2.385A1.547 1.547 0 0 0 7 1.947c-.41.01-.802.191-1.076.495l-3.16 3.512a1.502 1.502 0 0 0 .054 2.066l1.583 1.582Zm6.321 2.132 1.451-1.659a8.276 8.276 0 0 0 4.655 1.42h.672a1.5 1.5 0 1 0 0-3h-.672a5.363 5.363 0 0 1-3.767-1.561 1.407 1.407 0 0 0-1.111-.438 1.5 1.5 0 0 0-1.079.511l-3.5 4a1.5 1.5 0 0 0 .3 2.236l.664.442a5.261 5.261 0 0 1 2.2 5.484 1.5 1.5 0 1 0 2.928.651 8.285 8.285 0 0 0-2.741-8.086ZM22 18.495a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-17 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm3.125-14.5a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z"
                      ></path>
                    </svg>
                    <span>{businessInfo?.businessInfo?.[0]?.homelng?.[0]?.delivery_en ?? "Delivery"}</span>
                    <span>{businessInfo?.businessInfo?.[0]?.portal_info?.[0]
                      ?.delivery_estimate_time}min Approx</span>
                  </div>
                </Radio.Button>
              )}
            </Radio.Group>


          </div>
        )}

        {/* Dynamic Calculation Logic */}
        {cart.data?.length > 0 && (() => {
          const subtotal = cart.data?.reduce(
            (total, item) => total + item.quantity * item.delivery_item_price,
            0
          ) || 0;

          let deliveryFee = 0;

          if (businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.delivery_type_enable === 0) {
            const freeDeliveryThreshold = businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.free_delivery_above_subTotal;
            const defaultDeliveryCharge = businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.default_delivery_charge ?? 0;

            if (freeDeliveryThreshold && subtotal < freeDeliveryThreshold) {
              deliveryFee = Number(defaultDeliveryCharge); // Apply delivery charge if subtotal is below the threshold
            } else {
              deliveryFee = Number(defaultDeliveryCharge); // Apply default delivery charge when no threshold
            }
          }


          let PackageFee = 0;
          if (businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.packaging_charge_enable === 1) {
            PackageFee = Number(businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.packaging_charge ?? 0);
          }
          const discountRate = businessInfo?.businessInfo?.[0]?.pdq2_agreed_discount / 100 || 0;
          const discount = subtotal * discountRate; // Calculate the discount
          const TotalComparisonDiscount = subtotal * comparisionDiscount;
          let grandTotal;
          if (orderType === 0) {
            grandTotal = subtotal + PackageFee - (discount);
          } else {
            grandTotal = subtotal - (discount);
          }
          const totalSavings = (discount + TotalComparisonDiscount);

          return (
            <div>

              {/* Delivery = 0 and Collection = 1 */}
              {orderType === 0 &&
                subtotal < businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_delivery_order && (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ffe6e6',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '4px'
                  }}>

                    <Title level={4} style={{ margin: 0 }}>
                      The minimum order value is &pound;{businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_delivery_order}
                    </Title>
                  </div>
                )}

              {orderType === 1 &&
                subtotal < businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_pickup_order && (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ffe6e6',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '4px'
                  }}>

                    <Title level={4} style={{ margin: 0 }}>
                      The minimum order value is &pound;{businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_pickup_order}
                    </Title>
                  </div>
                )}


              <Title level={4} className="billHead">Bill Summary</Title>
              <div className="billSummary">
                <div className="billItemWrap">
                  <div className="billItemTitle">Sub Total</div>
                  <div className="billItemValue">&pound;{subtotal.toFixed(2)}</div>
                </div>
                {orderType === 0 && deliveryFee > 0 && (
                  <div className="billItemWrap" style={{ display: "none" }}>
                    <div className="billItemTitle">Delivery Fee</div>
                    <div className="billItemValue">&pound;{deliveryFee.toFixed(2)}</div>
                  </div>)}
                {orderType === 0 && businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.packaging_charge_enable === 1 && (
                  <div className="billItemWrap">
                    <div className="billItemTitle">Packaging Fee</div>
                    <div className="billItemValue">&pound;{PackageFee.toFixed(2)}</div>
                  </div>
                )}
                {discount > 0 && (<div className="billItemWrap">
                  <div className="billItemTitle">Direct Discount Amount</div>
                  <div className="billItemValue">&pound;{discount.toFixed(2)}</div>
                </div>)}
                <div className="billItemWrap">
                  <div className="billItemTitle">Grand Total</div>
                  <div className="billItemValue">&pound;{grandTotal.toFixed(2)}</div>
                </div>
                {totalSavings > 0 && (<div className="billTotalDiscountWrap">
                  <div className="billTotalDiscountTitle">Your Total Direct Order Savings</div>
                  <div className="billTotalDiscountValue">&pound;{totalSavings.toFixed(2)}</div>
                </div>)}
              </div>


              {orderType === 0 && subtotal > businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_delivery_order && (
                <div
                  className="billPayWrap"
                  style={{ marginTop: "16px", marginLeft: 0, marginRight: 0 }}
                >
                  <Button
                    type="primary"
                    onClick={handleCheckout}
                    className="checkoutButton"
                  >
                    Place Order &pound;{grandTotal.toFixed(2)} <ArrowRightOutlined />
                  </Button>
                </div>
              )}


              {orderType === 1 && subtotal > businessInfo?.businessInfo?.[0]?.charge_info?.[0]?.min_pickup_order && (
                <div
                  className="billPayWrap"
                  style={{ marginTop: "16px", marginLeft: 0, marginRight: 0 }}
                >
                  <Button
                    type="primary"
                    onClick={handleCheckout}
                    className="checkoutButton"
                  >
                    Place Order &pound;{grandTotal.toFixed(2)} <ArrowRightOutlined />
                  </Button>
                </div>
              )}


            </div>
          );
        })()}
      </Drawer>
    </Layout>
  );
};

export default MyHeader;
