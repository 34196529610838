import React, { useState, useContext } from "react";
import { Row, Col, Input, Radio, AutoComplete, Typography } from "antd";
import { BusinessContext } from "../../context/BusinessContext";
import "../../assets/css/Home.css";
import "../../assets/css/Font.css";

const { Title, Paragraph } = Typography;

const UKPostcodeSearch = () => {
  const [mode, setMode] = useState("pickup");
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  const { businessInfo, loading } = useContext(BusinessContext);
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="search-container">
        <Title>
        {businessInfo?.businessInfo?.[0]?.homelng?.[0]
            ?.collection_delivery_get_en ?? `Collection or Delivery, Get 30% OFF When you Order Direct!` }
        </Title>

        <Title level={2}>
          {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.we_back_to_serve_en ??
            "We're back to serve direct!"}
        </Title>
        <div className="radio-buttons">
          <Radio.Group onChange={handleModeChange} value={mode}>
            {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
              ?.pickup_type_enable === "0" && (
              <Radio.Button value="pickup" className="radioSpan">
                <svg viewBox="0 0 24 24" height="20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.869 3.284a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm2.109 9.162a8.519 8.519 0 0 1-2.418-1.266l-.288-.218a.25.25 0 0 0-.4.2v1.804a.993.993 0 0 0 .355.754 17.2 17.2 0 0 1 4.568 7.518 1.5 1.5 0 1 1-2.846.952 16.622 16.622 0 0 0-2.416-4.927.25.25 0 0 0-.433.081 17.332 17.332 0 0 1-2.56 5.309 1.5 1.5 0 1 1-2.344-1.873 12.745 12.745 0 0 0 2.213-4.7c.308-1.385.463-2.799.463-4.217v-1.357a.25.25 0 0 0-.38-.214 3.413 3.413 0 0 0-1.622 2.944 1.5 1.5 0 0 1-1.5 1.426 1.5 1.5 0 0 1-1.5-1.613 6.32 6.32 0 0 1 3.511-5.571 5.7 5.7 0 0 1 5.963.54l1.016.762a5.514 5.514 0 0 0 1.564.82l1.105.367a1.551 1.551 0 0 1 1.083 1.673 1.5 1.5 0 0 1-1.958 1.198l-1.176-.392Z"
                  ></path>
                </svg>{" "}
                {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.collection_en ??
                  "Collections"}{" "}
              </Radio.Button>
            )}
            {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
              ?.delivery_type_enable === "0" && (
              <Radio.Button value="delivery" className="radioSpan">
                <svg viewBox="0 0 24 24" height="20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.401 9.602c.281.281.666.439 1.062.439h.039a1.48 1.48 0 0 0 1.075-.496l3.157-3.507a1.505 1.505 0 0 0-.055-2.071L8.098 2.385A1.547 1.547 0 0 0 7 1.947c-.41.01-.802.191-1.076.495l-3.16 3.512a1.502 1.502 0 0 0 .054 2.066l1.583 1.582Zm6.321 2.132 1.451-1.659a8.276 8.276 0 0 0 4.655 1.42h.672a1.5 1.5 0 1 0 0-3h-.672a5.363 5.363 0 0 1-3.767-1.561 1.407 1.407 0 0 0-1.111-.438 1.5 1.5 0 0 0-1.079.511l-3.5 4a1.5 1.5 0 0 0 .3 2.236l.664.442a5.261 5.261 0 0 1 2.2 5.484 1.5 1.5 0 1 0 2.928.651 8.285 8.285 0 0 0-2.741-8.086ZM22 18.495a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-17 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm3.125-14.5a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z"
                  ></path>
                </svg>{" "}
                {businessInfo?.businessInfo?.[0]?.homelng?.[0]?.delivery_en ??
                  "Delivery"}{" "}
              </Radio.Button>
            )}
          </Radio.Group>
        </div>

        <div className="home_search">
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16" class="bi bi-arrow-right-short"><path data-v-e1dfa83c="" fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill="#C4C4C4"></path></svg> */}
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={500}
            style={{
              width: "100%",
              marginBottom: "12px",
            }}
            options={""}
            size="large"
          >
            <Input.Search
              size="large"
              placeholder={
                businessInfo?.businessInfo?.[0]?.homelng?.[0]
                  ?.enter_postcode_here_en ??
                "Please enter your postal code here. e.g. BN1 2AB"
              }
            />
          </AutoComplete>

          {mode === "delivery1" && (
            <div className="delivery-info">
              <Row gutter={10}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Input
                    placeholder="Suite, Apartment, Building, Floor, Etc."
                    className="searchText"
                    size="large"
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <Input
                    placeholder="City"
                    size="large"
                    className="searchText"
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <Input
                    placeholder="Zipcode"
                    size="large"
                    className="searchText"
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UKPostcodeSearch;
