export const loadGoogleMapsScript = (callback) => {
  if (!window.google) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDNyevW_K3I8Nk_6Rg6jntytgi0W0rNu58&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      callback();
    };
  } else {
    callback();
  }
};
