import React, { useContext, useState, useEffect } from "react";
import { Carousel, Button, Row, Typography } from "antd";
import { BusinessContext } from "../../context/BusinessContext";
import { RightOutlined } from "@ant-design/icons";
import "./SpecialMeals.css";

const { Title } = Typography;

const SpecialMeals = () => {
  const offers = [
    {
      title: "SPICY FRIED CHICKEN",
      description: "Limited Time Offer",
      image: "https://gramentheme.com/html/fresheat/assets/img/offer/offerThumb1_1.png",
      background: "https://gramentheme.com/html/fresheat/assets/img/bg/offerBG1_1.jpg",
      buttonText: "ORDER NOW",
      link: "menu",
    },
    {
      title: "TODAY SPECIAL FOOD",
      description: "Limited Time Offer",
      image: "https://gramentheme.com/html/fresheat/assets/img/offer/offerThumb1_2.png",
      background: "https://gramentheme.com/html/fresheat/assets/img/bg/offerBG1_1.jpg",
      buttonText: "ORDER NOW",
      link: "menu",
    },
    {
      title: "SPECIAL CHICKEN ROLL",
      description: "Limited Time Offer",
      image: "https://gramentheme.com/html/fresheat/assets/img/offer/offerThumb1_3.png",
      background: "https://gramentheme.com/html/fresheat/assets/img/bg/offerBG1_1.jpg",
      buttonText: "ORDER NOW",
      link: "menu.html",
    },
    {
      title: "DELICIOUS PIZZA",
      description: "Limited Time Offer",
      image: "https://gramentheme.com/html/fresheat/assets/img/offer/offerThumb1_1.png",
      background: "https://gramentheme.com/html/fresheat/assets/img/bg/offerBG1_1.jpg",
      buttonText: "ORDER NOW",
      link: "menu",
    },
    {
      title: "JUICY BURGER",
      description: "Limited Time Offer",
      image: "https://gramentheme.com/html/fresheat/assets/img/offer/offerThumb1_2.png",
      background: "https://gramentheme.com/html/fresheat/assets/img/bg/offerBG1_1.jpg",
      buttonText: "ORDER NOW",
      link: "menu",
    },
  ];

   const { businessInfo, loading } = useContext(BusinessContext);
   
  return (
    <div className="aboutSection">
      <div className="custom_container">
        <Title level={2} className="customHeading textCenter">
        {businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.popular_food_items_en ||
            `Popular Food Items`}
        
        </Title>
        <p className="popular-categories-description">
        {businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.popular_food_items_desc_en ||
            `Explore a wide selection of authentic and flavorful dishes, from sizzling starters and smoky tandoori delights to aromatic curries and rich balti dishes. Whether you're in the mood for a traditional biryani or a fresh vegetable side dish, our menu offers something for everyone. Discover our most popular categories and find your perfect meal today!`}
          
        </p>

        <Carousel
          className="special-meals-section"
          dots={false}
          arrows= {true}
          slidesToShow={3}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={3000}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {offers.map((offer, index) => (
            <div  style={{ padding: "0 10px" }} key={index}>
                <div className="special-meals-card">
              <div
                className="offer-card"
                style={{
                  backgroundImage: `url(${offer.background})`,
                }}
              >
                <div className="offer-content">
                  <h6>{offer.description}</h6>
                  <h3>{offer.title}</h3>
                  <Button
                    type="primary"
                    className="theme-btn style4"
                    href={offer.link}
                    icon={<RightOutlined />}
                  >
                    {offer.buttonText}
                  </Button>
                </div>
                <div className="offer-thumb">
                  <img className="thumbImg" src={offer.image} alt="offer" />
                  <div className="shape float-bob-x">
                    <img
                      src="https://gramentheme.com/html/fresheat/assets/img/shape/offerShape1_4.png"
                      alt="offer-shape"
                    />
                  </div>
                </div>
              </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default SpecialMeals;
