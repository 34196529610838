// src/components/BannerSlider.js
import React, { useState, useEffect, useContext } from "react";
import { Carousel } from "antd";
import { BusinessContext } from "../../context/BusinessContext";

const BannerSlider = () => {
  const { businessInfo } = useContext(BusinessContext);
  return (
    <Carousel    dots={false}
    arrows= {true} autoplay>
      {Array.isArray(businessInfo?.businessInfo?.[0]?.flashbanner_info) &&
      businessInfo.businessInfo[0].flashbanner_info.length > 0 ? (
        businessInfo.businessInfo[0].flashbanner_info.map((element, index) => (
          <div className="SliderBanner">
            <img
              key={index}
              src={element.caption_img} // assuming each element has an `caption_img` property
              alt={`Banner ${index + 1}`}
              style={{ width: "100%" }}
            />
          </div>
        ))
      ) : (
        // Display default image if flashbanner_info is not an array or is empty
        <div className="SliderBanner">
          <img
            src="https://consumer-static-assets.wolt.com/frontpage-assets/hero-images/1_Monday.jpg"
            alt="Default Banner"
            style={{ width: "100%" }}
          />
        </div>
      )}
    </Carousel>
  );
};

export default BannerSlider;
