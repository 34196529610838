import React, { useContext, useState } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import { Carousel, Typography, Rate, Row, Col, Modal, Button } from "antd";
import moment from "moment";
import "../Reviews/TopReviews.css";

const { Title, Text } = Typography;

const TopReviews = () => {
  const { businessInfo } = useContext(BusinessContext);
  const reviews = businessInfo?.businessInfo?.[0]?.resreviews || [];

  const [topReviewModalVisible, setTopReviewModalVisible] = useState(false);
  const [topReviewSelected, setTopReviewSelected] = useState(null);

  const handleTopReviewShowMore = (review) => {
    setTopReviewSelected(review);
    setTopReviewModalVisible(true);
  };

  const handleTopReviewCancel = () => {
    setTopReviewModalVisible(false);
    setTopReviewSelected(null);
  };

  if (reviews.length === 0) {
    return null;
  }

  return (
    <div className="reviewSection">
      <div className="custom_container">
        <Title level={2} className="customHeading textCenter">
          {businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.top_cust_reviews_en ||
            "Top Customer Reviews"}
        </Title>
        <p className="customer-reviews-description">
        {businessInfo?.businessInfo?.[0]?.howitworkslng?.[0]?.top_cust_reviews_desc_en ||
            `Our customers love our food and services! Here are some of the top
          reviews highlighting the best experiences from our valued customers.
          See what people are saying and why they keep coming back for more!`}
        </p>
        
        <Carousel
          className="special-meals-section"
          dots={false}
          arrows= {true}
          slidesToShow={3}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={3000}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {reviews.map((review, idx) => (
            <div  style={{ padding: "0 10px" }} key={idx}>
                <div className="reviews-box-card">
                  <div className="reviewBoxWrap">
                    <div className="reviewInfo">
                      <div className="reviewUserImg">
                        <img
                          src={
                            review.review_user_img ||
                            businessInfo?.businessInfo?.[0]?.restaurant_logo
                          }
                          alt={review.review_user_name || "No Name"}
                        />
                      </div>
                      <div className="reviewUserName">
                        <Title level={5} className="reviewHeading">
                          {review.review_user_name || "Anonymous User"}
                        </Title>
                      </div>
                    </div>
                    <Text className="reviewText">
                      {review.review_comment?.length > 85
                        ? `${review.review_comment.slice(0, 85)}...`
                        : review.review_comment || "No comment provided."}
                    </Text>
                    {review.review_comment?.length > 85 && (
                      <Button
                        type="link"
                        onClick={() => handleTopReviewShowMore(review)}
                        className="showMoreBtn"
                      >
                        Show More
                      </Button>
                    )}
                    <div className="ratingStar">
                      <Rate
                        allowHalf
                        disabled
                        defaultValue={review.review_rating || 0}
                      />
                      <Text type="secondary" className="reviewDate">
                        {review.created_at
                          ? moment(review.created_at).format(
                              "MMM DD, YYYY [at] hh:mm A"
                            )
                          : "No Date Available"}
                      </Text>
                    </div>
                  </div>
              </div>
            </div>
          ))}
        </Carousel>

        <Modal
          title="Review Details"
          visible={topReviewModalVisible}
          onCancel={handleTopReviewCancel}
          footer={null}
        >
          {topReviewSelected && (
            <div>
              <div className="modalUserInfo">
                <div className="modalUserImg">
                  <img
                    src={
                      topReviewSelected.review_user_img ||
                      businessInfo?.businessInfo?.[0]?.restaurant_logo
                    }
                    alt={topReviewSelected.review_user_name || "No Name"}
                  />
                </div>
                <div className="modalUserName">
                  <Title level={5}>
                    {topReviewSelected.review_user_name || "Anonymous User"}
                  </Title>
                </div>
              </div>
              <Text className="modalReviewText">
                {topReviewSelected.review_comment}
              </Text>
              <div className="modalRatingStar">
                <Rate
                  allowHalf
                  disabled
                  defaultValue={topReviewSelected.review_rating || 0}
                />
                <Text type="secondary" className="modalReviewDate">
                  {topReviewSelected.created_at
                    ? moment(topReviewSelected.created_at).format(
                        "MMM DD, YYYY [at] hh:mm A"
                      )
                    : "No Date Available"}
                </Text>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default TopReviews;
