import React, { useState, useContext } from "react";
import { Row, Col, Input, Radio, AutoComplete, Typography } from "antd";
import { BusinessContext } from "../../context/BusinessContext";
import FeatureFoodItems from "../features/Menu/FeatureFoodItems";
import { Helmet } from 'react-helmet';
import BannerSlider from "../BannerSlider/BannerSlider";
import TopReviews from "../Reviews/TopReviews";
import HowItWorks from "../HowItWorks";
import Openinghours from "../Openinghours/Openinghours";
import SpecialMeals from "../SpecialMeals";
import PopularCategories from "../PopularCategories";
import OurServicesList from "../OurServices";
import "../../assets/css/Home.css";
import "../../assets/css/Font.css";
import AboutUs from "../About/About";
import Ticker from "../Ticker/Ticker";
import UKPostcodeSearch from "./UKPostcodeSearch";
import DirectOrderSearch from "./DirectOrderSearch";
const { Title } = Typography;

const Home = () => {
  const { businessInfo, loading } = useContext(BusinessContext);
  console.log(businessInfo?.businessInfo?.[0]?.portal_info?.[0]
    ?.opening_hours_section_enable);
  return (
    <>
         <Helmet>
        {/* Title */}
        <title>Curry Inn | Authentic Indian Cuisine in Haywards Heath</title>
        <meta name="description" content="Indulge in the finest Indian curries, tandoori dishes, and homemade naan at Curry Inn. Visit us for an unforgettable dining experience in Haywards Heath." />
        <meta name="keywords" content="Indian restaurant Haywards Heath, Authentic curry, Tandoori dishes, Best naan, Indian food delivery" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.curryinn.co.uk/" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Curry Inn | Authentic Indian Cuisine in Haywards Heath" />
        <meta property="og:description" content="Indulge in the finest Indian curries, tandoori dishes, and homemade naan at Curry Inn. Visit us for an unforgettable dining experience in Haywards Heath." />
        <meta property="og:image" content="https://www.curryinn.co.uk/images/og-image.jpg" />
        <meta property="og:url" content="https://www.curryinn.co.uk/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Curry Inn | Authentic Indian Cuisine in Haywards Heath" />
        <meta name="twitter:description" content="Explore the rich flavors of Indian cuisine at Curry Inn. From mouthwatering curries to fresh naan, we serve the best food in Haywards Heath." />
        <meta name="twitter:image" content="https://www.curryinn.co.uk/images/og-image.jpg" />

        {/* Structured Data (JSON-LD) for SEO */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Restaurant",
              "name": "Curry Inn",
              "description": "Curry Inn offers the best authentic Indian cuisine in Haywards Heath. Enjoy traditional curries, tandoori dishes, and naan in a welcoming atmosphere.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "[Street Address]",
                "addressLocality": "Haywards Heath",
                "addressRegion": "[Region]",
                "postalCode": "[Postal Code]",
                "addressCountry": "UK"
              },
              "telephone": "[Your Restaurant's Phone Number]",
              "url": "https://www.curryinn.co.uk",
              "image": "https://www.curryinn.co.uk/images/og-image.jpg",
              "menu": "https://www.curryinn.co.uk/menu",
              "priceRange": "££",
              "servesCuisine": "Indian",
              "paymentAccepted": "Cash, Credit Card, Debit Card",
              "sameAs": [
                "https://www.facebook.com/curryinn",
                "https://www.instagram.com/curryinn",
                "https://www.twitter.com/curryinn"
              ]
            }
          `}
        </script>
      </Helmet>
     <Ticker />
      <Row className="searchSection">
        <Col
          xs={24}
          sm={24}
          md={12}
          xl={12}
          xxl={12}
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <DirectOrderSearch />
          {/* <UKPostcodeSearch /> */}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          xl={12}
          xxl={12}
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <BannerSlider /> {/* Your slider component */}
        </Col>
      </Row>
     
      {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.how_it_work_section_enable === 1 && <HowItWorks />}

      {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.about_home_section_enable === 1 && <AboutUs />}

      {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.popular_food_items_section_enable === 1 && <SpecialMeals />}

      {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.popular_categories_section_enable === 1 && <PopularCategories />}

      {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.opening_hours_section_enable === 1 && <Openinghours />}

      {businessInfo?.businessInfo?.[0]?.portal_info?.[0]
        ?.restaurant_service_section_enable === 1 && <OurServicesList />}

      {businessInfo?.businessInfo?.[0]?.portal_info?.[0]?.review_enable ===
        1 && <TopReviews />}
    </>
  );
};

export default Home;
