import React, { useState, useEffect, useContext } from "react";
import { BusinessContext } from "../../context/BusinessContext";
import {
  Typography,
  Input,
  Button,
  Form,
  Row,
  Col,
  Space,
  message,
} from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import "../../assets/css/Font.css";
import { SubmitReservation } from "../../utils/api";
import aboutimg from "../../assets/images/video-cover-image-4.jpg";
import cuisineIco from "../../assets/images/cuisine.svg";
import mealsIco from "../../assets/images/cutlery.svg";

const { Title } = Typography;
const { TextArea } = Input;

const ContactUs = () => {
  const { businessInfo } = useContext(BusinessContext);
  const locationInfo =
    businessInfo?.businessInfo?.[0]?.location_info?.[0] || {};
  const AddressInfo = [
    locationInfo.house_number,
    locationInfo.street_name_en,
    locationInfo.city_en,
    locationInfo.postcode,
    locationInfo.country_en,
  ]
    .filter(Boolean) // Ensures only truthy values are included (avoids undefined or null)
    .join(" "); // Joins the address components with a space
  const AddressMap = `https://maps.google.it/maps?&q=${encodeURIComponent(
    AddressInfo
  )}&output=embed`;

  const [form] = Form.useForm();

  // Handle form submission and API request
  const handleSubmit = async (values) => {
    try {
      const response = await SubmitReservation(values); // Await the API call
      if (response) {
        message.success({
                  content: response.message || "Thank you for reaching out to us! We have received your enquiry and will get back to you as soon as possible.",
                  duration: 5, 
                });
        form.resetFields(); // Reset the form fields
      } else {
        message.error({
          content: response.message || "Failed to submit conttact enquiry.",
          duration: 5, 
        });
        message.error("Failed to submit reservation."); // Error message if response is null
      }
    } catch (error) {
      message.error("An error occurred while submitting the conttact enquiry.");
    }
  };
  return (
    <>
      {/* Banner Section */}
      <div className="menuBanner">
        <img
          src={aboutimg}
          alt="Menu Banner"
          style={{ width: "100%", height: "235px", objectFit: "cover" }}
        />
        <div className="innerBannerTitle">
          <Title level={2} style={{ color: "#fff", margin: "0" }}>
            Contact Us
          </Title>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="custom_container contactSection">
        <Row gutter={32} className="contactWrap">
          {/* Left Side: Contact Form */}
          <Col xs={24} md={12}>
            <div className="contactForm">
              <Title level={3} className="customInnerHead">
                Get in Touch
              </Title>
              <Form layout="vertical" onFinish={handleSubmit} form={form}>
                <Row gutter={15}>
                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="contact_name"
                      label="Name"
                      rules={[
                        { required: true, message: "Please enter your name." },
                      ]}
                    >
                      <Input
                        placeholder="Please enter your name."
                        className="custom_input_field"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="contact_email"
                      label="Email Address"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please enter a valid email.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Please enter a valid email."
                        className="custom_input_field"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="contact_number"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Please enter your phone number."
                        className="custom_input_field"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Form.Item
                      name="contact_subject"
                      label="Subject"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your subject.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Please enter your subject."
                        className="custom_input_field"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                    <Form.Item
                      name="contact_message"
                      label="Message"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your message.",
                        },
                        {
                          max: 500,
                          message: "Message cannot exceed 500 characters",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Please enter your message."
                        className="custom_textarea_field"
                        rows={8}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="checkoutButton"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </Col>

          {/* Right Side: Contact Details and Social Media Links */}
          <Col xs={24} md={12}>
            <div className="contactDetails">
              <Title level={3} className="customInnerHead">
                Contact Information
              </Title>
              <Space direction="vertical" size="middle">
                <div className="contactItem">
                  <PhoneOutlined
                    style={{
                      color: "#1890ff",
                      fontSize: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <span>
                    <b>Phone:</b>{" "}
                    {businessInfo?.businessInfo?.[0]?.restaurant_phone || "N/A"}
                  </span>
                </div>
                <div className="contactItem">
                  <MailOutlined
                    style={{
                      color: "#1890ff",
                      fontSize: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <span>
                    <b>Email:</b>{" "}
                    {businessInfo?.businessInfo?.[0]?.email_address || "N/A"}
                  </span>
                </div>
                <div className="contactItem">
                  <EnvironmentOutlined
                    style={{
                      color: "#1890ff",
                      fontSize: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <span>
                    <b>Address:</b> {AddressInfo}
                  </span>
                </div>

                <div className="contactItem">
                  <img
                    src={cuisineIco}
                    style={{ width: "20px", marginRight: "8px" }}
                  />
                  <span>
                    <b>Cuisines:</b>{" "}
                    {businessInfo?.businessInfo?.[0]?.restaurant_cuisine_en ||
                      "N/A"}
                  </span>
                </div>

                <div className="contactItem">
                  <img
                    src={mealsIco}
                    style={{ width: "20px", marginRight: "8px" }}
                  />
                  <span>
                    <b>Meals:</b>{" "}
                    {businessInfo?.businessInfo?.[0]?.meals_available_en ||
                      "N/A"}
                  </span>
                </div>
              </Space>

              {/* Social Media Links */}
              <div className="socialFollowWrap">
                <Title level={4} style={{ marginTop: 0 }}>
                  Follow Us
                </Title>
                <Space size="large">
                  <a
                    href={
                      businessInfo?.businessInfo?.[0]?.social_facebook || ""
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookOutlined className="facebookIco" />
                  </a>
                  <a
                    href={
                      businessInfo?.businessInfo?.[0]?.social_instagram || ""
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramOutlined className="instagramIco" />
                  </a>
                  <a
                    href={businessInfo?.businessInfo?.[0]?.social_youtube || ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YoutubeOutlined className="youtubeIco" />
                  </a>
                </Space>
              </div>
            </div>
          </Col>
        </Row>
        {/* Map Section */}
        <div
          className="mapSection"
          style={{
            marginTop: "40px",
            height: "300px",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <iframe
            title="location-map"
            src={AddressMap}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
