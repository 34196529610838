// src/context/BusinessContext.js
import React, { createContext, useEffect, useState } from "react";
import { fetchBusinessInfo } from "../utils/api";

// Create Context
export const BusinessContext = createContext();

// BusinessProvider Component
export const BusinessProvider = ({ children }) => {
  const [businessInfo, setBusinessInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  // Load business info once when the component mounts
  useEffect(() => {
    const loadBusinessInfo = async () => {
      try {
        const data = await fetchBusinessInfo();
        setBusinessInfo(data);
      } catch (error) {
        console.error("Failed to load business info:", error);
      } finally {
        setLoading(false); // Once the data is fetched (or failed), set loading to false
      }
    };

    loadBusinessInfo();
  }, []);

  // Provide both the businessInfo and loading state to the rest of the app
  return (
    <BusinessContext.Provider value={{ businessInfo, loading }}>
      {children}
    </BusinessContext.Provider>
  );
};
